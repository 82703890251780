import React, { MouseEvent } from 'react';
import { appStyles } from '../../helpers';
import {
    Dialog,
    DialogType,
    Stack,
    FontWeights,
    PrimaryButton,
    Text,
} from '@fluentui/react';
import { useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';

type Props = {
    text: string;
    hidden: boolean;
    onConfirm: (event: MouseEvent<HTMLButtonElement>) => void;
    onClose: (event: MouseEvent<HTMLButtonElement>) => void;
    onDismiss: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const ConfirmDialog = ({
    text,
    hidden,
    onConfirm,
    onClose,
    onDismiss,
}: Props): JSX.Element => {
    const translate = useAppSelector(selectTranslate);

    return (
        <Dialog
            styles={appStyles.DialogStyle}
            hidden={hidden}
            onDismiss={onDismiss}
            dialogContentProps={{ type: DialogType.close }}
        >
            <Stack tokens={{ childrenGap: '2rem' }}>
                <Text
                    styles={{
                        root: {
                            textAlign: 'center',
                            fontWeight: FontWeights.semibold,
                        },
                    }}
                    variant="mediumPlus"
                >
                    {text}
                </Text>
                <Stack
                    horizontal={true}
                    horizontalAlign="center"
                    tokens={{ childrenGap: '1rem' }}
                >
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        onClick={onConfirm}
                    >
                        {translate('button_yes')}
                    </PrimaryButton>
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        onClick={onClose}
                    >
                        {translate('close')}
                    </PrimaryButton>
                </Stack>
            </Stack>
        </Dialog>
    );
};
