import React, { CSSProperties, useEffect, useLayoutEffect } from 'react';
import {
    Row,
    SortingState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { ServerFile } from '../../types';
import { Stack, Text } from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { Breadcrumbs, PathPart } from '../common/breadcrumbs';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';
import { FileDetailsContent } from './components/fileDetailsContent';
import {
    cutSortedPathPartsTill,
    formatBytes,
    getStartingPathParts,
    isNonHiddenFile,
    sortFilesDirectoriesFirstInAlphabeticalOrder,
} from '../../helpers/fileExplorer';
import {
    getInitialServerFilesThunk,
    getServerFilesWithLoader,
    openFileInNextcloudThunk,
} from '../../store/actions/app/thunks';
import { TableHeader } from './tableRenderers/tableHeader';
import { HeaderColumnContent } from './components/headerColumnContent';
import './fileExplorer.css';
import { selectServerFiles } from '../../store/selectors/app';
import { capitilize, storeKeys } from '../../helpers';
import { useMediaQuery, useUnmount } from '../../helpers/hooks';

const breadcrumbsContainerStyle: CSSProperties = { overflow: 'auto ' };

const columnHelper = createColumnHelper<ServerFile>();

const columns = [
    columnHelper.accessor('name', {
        header: (context) => (
            <HeaderColumnContent
                text="Name"
                isSorted={context.column.getIsSorted()}
            />
        ),
        cell: (context) => <FileDetailsContent file={context.row.original} />,
        sortingFn: (rowA, rowB) =>
            sortFilesDirectoriesFirstInAlphabeticalOrder(
                rowA.original,
                rowB.original,
            ),
    }),
    columnHelper.accessor('contentlength', {
        header: (context) => (
            <HeaderColumnContent
                text="Size"
                isSorted={context.column.getIsSorted()}
            />
        ),
        cell: (context) => {
            if (context.row.original.type === 'directory') {
                return null;
            }

            return <Text>{formatBytes(context.getValue())}</Text>;
        },
    }),
    columnHelper.accessor('lastmod', {
        header: (context) => (
            <HeaderColumnContent
                text="Modified"
                isSorted={context.column.getIsSorted()}
            />
        ),
        cell: (context) => <Text>{moment(context.getValue()).fromNow()}</Text>,
    }),
];

export const FileExplorer = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getInitialServerFilesThunk());
    }, []);

    const serverFiles = useAppSelector(selectServerFiles) ?? [];
    // appActions.getServerFiles(<path>) returns array
    // where first item is a folder for <path>
    const files = serverFiles.filter(isNonHiddenFile).slice(1);

    const [sorting, setSorting] = useState<SortingState>([
        { id: 'name', desc: false },
    ]);

    const [columnVisibility, setColumnVisibility] = useState({});
    const matches = useMediaQuery('(min-width: 768px)');
    useLayoutEffect(() => {
        setColumnVisibility({
            contentlength: matches,
            lastmod: matches,
        });
    }, [matches]);

    const table = useReactTable<ServerFile>({
        data: files,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableSortingRemoval: false,
        initialState: {
            sorting,
            columnVisibility,
        },
        state: {
            sorting,
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
    });

    const translate = useAppSelector(selectTranslate);
    const [pathToSelected, setPathToSelected] = useState<PathPart[]>(() => {
        const startingPathParts = getStartingPathParts();
        return (
            startingPathParts ?? [
                {
                    name: capitilize(translate('home')),
                    path: files[0]?.parentPath ?? '',
                },
            ]
        );
    });
    useUnmount(() => {
        localStorage.setItem(
            storeKeys.FILE_EXPLORER_PATH,
            JSON.stringify(pathToSelected),
        );
    });

    const onPathPartClick = (
        e: React.MouseEvent<HTMLElement>,
        clickedPath: string,
    ) => {
        setPathToSelected(cutSortedPathPartsTill(pathToSelected, clickedPath));
        dispatch(getServerFilesWithLoader(clickedPath));
    };

    const mapFileRows = (row: Row<ServerFile>): JSX.Element => {
        const file = row.original;

        const navigateToDirectory = (): void => {
            const item = {
                name: file.name,
                path: file.path,
            };
            setPathToSelected([...pathToSelected, item]);
            dispatch(getServerFilesWithLoader(file.path));
        };

        const openNextcloudFile = (): void =>
            dispatch(openFileInNextcloudThunk(file.fileId));

        const onClick =
            file.type === 'directory' ? navigateToDirectory : openNextcloudFile;

        return (
            <tr
                key={row.id}
                className="file-explorer__file-row"
                onClick={onClick}
            >
                {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="file-explorer__file-cell">
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                        )}
                    </td>
                ))}
            </tr>
        );
    };

    return (
        <Stack
            className="file-explorer"
            horizontal={false}
            tokens={{ childrenGap: '0.5rem' }}
        >
            <Breadcrumbs
                containerStyle={breadcrumbsContainerStyle}
                pathParts={pathToSelected}
                onPathPartClick={onPathPartClick}
            />
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(TableHeader)}
                        </tr>
                    ))}
                </thead>
                <tbody>{table.getRowModel().rows.map(mapFileRows)}</tbody>
            </table>
        </Stack>
    );
};
