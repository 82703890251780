/**
 * Component displays the button with predefined (onstants) values
 *
 */

import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Stack,
    MessageBarType,
    MessageBar,
    IIconProps,
    mergeStyleSets,
    Dialog,
    TextField,
    DialogFooter,
    PrimaryButton,
    DialogType,
} from '@fluentui/react';
import { appStyles } from '../../helpers';

const NewFolderDialog = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const [name, setName] = useState('');
    const [folderExists, setFolderExists] = useState(false);

    const dialogContentProps = {
        type: DialogType.close,
    };

    useEffect(() => {}, []);

    const classes = mergeStyleSets({
        iconError: {
            fontFamily: 'FabricMDL2Icons-3',
            color: 'white',
        },
        dismissIconError: {
            fontFamily: 'FabricMDL2Icons',
            color: 'white !important',
        },
    });

    const errorIcon: IIconProps = {
        iconName: 'ErrorBadge',
        styles: {
            root: classes.iconError,
        },
    };

    const dismissErrorIcon: IIconProps = {
        iconName: 'Clear',
        styles: {
            root: classes.dismissIconError,
        },
    };

    const saveName = async () => {
        console.log('The action saveName');
        if (await props.checkFolderExist(name)) {
            setFolderExists(true);
        } else {
            props.onDismiss(true);
            props.createFolder(name);
            setName('');
        }
    };

    const changeName = (name: string) => {
        setFolderExists(false);
        setName(name);
    };

    const hideDialog = async (e) => {
        e.stopPropagation();
        props.onDismiss(true);
        setName('');
        setFolderExists(false);
    };

    const closeError = async (e) => {
        e.stopPropagation();
        setFolderExists(false);
    };

    return (
        <Stack>
            <Dialog
                styles={appStyles.DialogStyle}
                hidden={props.hidden}
                onDismiss={hideDialog}
                dialogContentProps={dialogContentProps}
            >
                <Stack tokens={{ childrenGap: 10 }}>
                    {folderExists ? (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            messageBarIconProps={errorIcon}
                            dismissIconProps={dismissErrorIcon}
                            className={appStyles.globalStyles.error}
                            isMultiline={true}
                            onDismiss={closeError}
                            dismissButtonAriaLabel="Close"
                        >
                            {uiState.translate('newfolder_exists')}
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    <TextField
                        styles={appStyles.textBoxStyle}
                        value={name}
                        label={uiState.translate('button_newfolder')}
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                            changeName(e.target.value)
                        }
                    />
                    <DialogFooter>
                        <PrimaryButton
                            styles={appStyles.primaryButton}
                            text={uiState.translate('button_create')}
                            disabled={name.trim() === ''}
                            onClick={saveName}
                        />
                    </DialogFooter>
                </Stack>
            </Dialog>
        </Stack>
    );
};

export default NewFolderDialog;

NewFolderDialog.propTypes = {
    hidden: PropTypes.bool,
    onDismiss: PropTypes.func,
    createFolder: PropTypes.func,
    checkFolderExist: PropTypes.func,
};
