import { registerIconAlias } from '@uifabric/styling';

export const registerIconAliases = () => {
    registerIconAlias('trash', 'delete');
    registerIconAlias('onedrive', 'onedrivelogo');
    registerIconAlias('alertsolid12', 'eventdatemissed12');
    registerIconAlias('sixpointstar', '6pointstar');
    registerIconAlias('twelvepointstar', '12pointstar');
    registerIconAlias('toggleon', 'toggleleft');
    registerIconAlias('toggleoff', 'toggleright');
    registerIconAlias('edgelogo', 'edgeoldlogo');
    registerIconAlias('powerappscds', 'commondataservicecds');
    registerIconAlias('d365talenthrcore', 'd365corehr');
    registerIconAlias('crmcustomerinsightsapp', 'insights');
    registerIconAlias('microsoftflowlogo', 'powerautomatelogo');
};

export default registerIconAliases;
