import { IButtonProps, IconButton } from '@fluentui/react';
import React, { MouseEvent } from 'react';
import { useAppDispatch } from '../../../store';
// eslint-disable-next-line max-len
import { copyFileShareLinkToClipboardThunk } from '../../../store/actions/app/thunks';
import { throttle } from '../../../helpers';

type Props = {
    fileId: number;
} & Omit<IButtonProps, 'onClick'>;

export const ShareLinkButton = ({ fileId, ...rest }: Props): JSX.Element => {
    const dispath = useAppDispatch();
    const handleLink = throttle(
        () => dispath(copyFileShareLinkToClipboardThunk(fileId)),
        1500,
    );
    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        handleLink();
    };

    return (
        <IconButton
            // TODO: maybe remove
            styles={{
                iconHovered: {
                    color: 'black',
                },
            }}
            iconProps={{ iconName: 'Share' }}
            onClick={onClick}
            {...rest}
        />
    );
};
