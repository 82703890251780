import React from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, ActionButton, PrimaryButton } from '@fluentui/react';
import { appStyles } from '../../helpers';

const WizardButtons = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

    return (
        <Stack
            horizontal
            className={appStyles.globalStyles.sectionLayout20}
            horizontalAlign="space-between"
        >
            <Stack horizontalAlign="start" verticalAlign="center">
                {props.wizardPos > 0 ? (
                    <ActionButton
                        styles={appStyles.actionButton}
                        text={uiState.translate('button_prev')}
                        onClick={props.onPrev}
                    />
                ) : (
                    <></>
                )}
            </Stack>
            <Stack horizontalAlign="end" verticalAlign="center">
                {props.wizardPos >= 0 &&
                props.wizardPos < props.wizardCount - 1 ? (
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        text={uiState.translate('button_next')}
                        onClick={props.onNext}
                    />
                ) : (
                    <></>
                )}
            </Stack>
        </Stack>
    );
};

export default WizardButtons;

WizardButtons.propTypes = {
    wizardPos: PropTypes.number,
    wizardCount: PropTypes.number,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
};
