import React from 'react';
import { Stack, TextField, PrimaryButton } from '@fluentui/react';
import { appStyles } from '../../helpers';
import { useAppSelector, useAppDispatch } from '../../store';
import { authActions } from '../../store/actions/auth/actions';
import { selectUser } from '../../store/selectors/auth';
import { selectTranslate } from '../../store/selectors/ui';
// eslint-disable-next-line max-len
import { PageHeadingWithBackButton } from '../../components/common/pageHeadingWithBackButton';
import { history } from '../../helpers/history';

export const SettingsAccountSection = () => {
    const user = useAppSelector(selectUser);
    const translate = useAppSelector(selectTranslate);
    const dispatch = useAppDispatch();

    if (!user) {
        return null;
    }

    const onLogout = (): void => {
        console.log('onLogout method called');
        dispatch(authActions.logout(true));
    };

    return (
        <>
            <PageHeadingWithBackButton
                text={translate('settings_account')}
                onBackButtonClick={history.goBack}
            />
            <Stack tokens={{ childrenGap: 10 }}>
                <TextField
                    styles={appStyles.textBoxStyle}
                    label={translate('settings_server_url')}
                    value={user.server}
                    readOnly={true}
                />
                <TextField
                    styles={appStyles.textBoxStyle}
                    label={translate('settings_account')}
                    value={user.loginName}
                    readOnly={true}
                />
                <Stack horizontalAlign="start">
                    <PrimaryButton
                        styles={appStyles.primaryButton}
                        text={translate('logout')}
                        onClick={onLogout}
                    />
                </Stack>
            </Stack>
        </>
    );
};
