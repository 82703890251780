import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
    Stack,
    ContextualMenu,
    Text,
    ScrollablePane,
    ScrollbarVisibility,
    IContextualMenuProps,
    IContextualMenuItem,
    PrimaryButton,
} from '@fluentui/react';
import { appStyles, Logger, Utilities } from '../helpers';
import {
    ServerFilesDialog,
    WarningDialog,
    ProgressDialog,
    FileOverwriteDialog,
} from '../components/dialogs';
import {
    WizardPassword,
    WizardDate,
    WizardButtons,
    WizardOption,
    WizardOptionConfirm,
    WizardFiles,
} from '../components/wizard';
import { appActions } from '../store/actions/app/actions';
import { validationApi } from '../services/validationApi';
import { cloneDeep, forEach, toNumber } from 'lodash';
import {
    appConstants,
    NotificationStatus,
    AppErrorCode,
} from '../store/actions/app/actionTypes';
import { useFilePicker } from 'use-file-picker';
import { passwordApi, webdavApi, License, LicenseLevel } from 'nextcloud-api';
import * as path from 'path';
import { withUserConfigGuard } from '../hoc/userConfigGuard';
import { withLicenseLevelGuard } from '../hoc/licenseLevelGuard';
import { withTabs } from '../hoc/tabs';

const ShareFilePage = () => {
    const dispatch = useDispatch();
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication,
    );

    const [filesDialogHidden, setFilesDialogHidden] = useState(true);
    const [foldersDialogHidden, setFoldersDialogHidden] = useState(true);
    const [warningDialogHidden, setWarningDialogHidden] = useState(true);
    const [progressDialogHidden, setProgressDialogHidden] = useState(true);
    const [fileOverwriteDialogHidden, setFileOverwriteDialogHidden] =
        useState(true);

    const [password, setPassword] = useState('');
    const [date, setDate] = useState(null);
    const [warningMessage, setWarningMessage] = useState('');
    const [_license, setLicense] = useState(null);
    const [wizardPos, setWizardPos] = useState(0);
    const [fileName, setFileName] = useState('');
    const [fileNewName, setFileNewName] = useState('');

    const [openFileSelector, { filesContent, plainFiles, clear }] =
        useFilePicker({
            readAs: 'ArrayBuffer',
            multiple: false,
        });

    const onPswValidation = async () => {
        return showWarning(await validationApi.validatePassword(password));
    };

    const onDateValidation = async () => {
        return showWarning(await validationApi.validateDate(date));
    };

    const onFilesValidation = async () => {
        if (appState.filesToUpload.length == 0) {
            return showWarning(
                uiState.translate('main_page_no_attach_selected'),
            );
        } else if (appState.filesToUpload.length > 1) {
            return showWarning(
                uiState.translate('main_page_many_attach_selected'),
            );
        } else if (
            !Utilities.isNANObject(appState.filesToUpload[0].content) &&
            Utilities.isNANObject(appState.destinationFolder)
        ) {
            return showWarning(uiState.translate('main_page_no_dest_folder'));
        }
        return true;
    };

    const wizard = {
        pages: [
            {
                id: 'files',
                validation: onFilesValidation,
            },
            {
                id: 'password',
                validation: onPswValidation,
            },
            {
                id: 'date',
                validation: onDateValidation,
            },
            {
                id: 'confirm',
                validation: () => {
                    return true;
                },
            },
        ],
    };

    useEffect(() => {
        async function loadData() {
            if (authentication.userConfig !== null) {
                dispatch({
                    type: appConstants.APP_FILES_TO_UPLOAD,
                    payload: { filesToUpload: [] },
                });
                const license = new License(authentication.userConfig);
                setLicense(license);
                console.log('license.IsLicenseValid ', license.IsLicenseValid);
                if (!license.IsLicenseValid) {
                    dispatch({
                        type: appConstants.APP_SHOW_NOTIFICATION,
                        payload: {
                            status: NotificationStatus.Error,
                            errorCode: AppErrorCode.CustomError,
                            message: uiState.translate('license_notvalid'),
                        },
                    });
                }
            }
        }
        loadData();
    }, [authentication.userConfig]);

    useEffect(() => {
        async function loadData() {
            setDate('');
            if (authentication.filesSharingSettings !== null) {
                if (authentication.filesSharingSettings.PasswordEnabled) {
                    const passwordResult = await passwordApi.generatePassword(
                        appState.appConfig.stubUrl,
                        authentication.user,
                    );
                    if (passwordResult.success === true) {
                        setPassword(passwordResult.result.ocs.data.password);
                    }
                }
                if (authentication.filesSharingSettings.ExpireDateEnabled) {
                    const nowDate = new Date();
                    if (
                        !Utilities.isNANObject(
                            authentication.filesSharingSettings.ExpireDateDays,
                        ) &&
                        toNumber(
                            authentication.filesSharingSettings.ExpireDateDays,
                        ) > 0
                    )
                        nowDate.setDate(
                            nowDate.getDate() +
                                toNumber(
                                    authentication.filesSharingSettings
                                        .ExpireDateDays,
                                ),
                        );
                    else nowDate.setDate(nowDate.getDate() + 1);
                    setDate(nowDate);
                }
            }
        }
        loadData();
    }, [authentication.filesSharingSettings]);

    useEffect(() => {
        if (appState.transferCompleted) {
            setProgressDialogHidden(true);
        }
    }, [appState.transferCompleted]);

    useEffect(() => {
        async function loadData() {
            if (
                plainFiles !== null &&
                plainFiles !== undefined &&
                plainFiles.length > 0
            ) {
                const newFilesList = [];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                forEach(plainFiles, (file: any) => {
                    const content =
                        filesContent[plainFiles.indexOf(file)].content;
                    const driveitem = {
                        name: file.name,
                        type: 'file',
                        lastmod: file.lastModifiedDate,
                        contenttype: file.type,
                        content: content,
                        selected: false,
                    };
                    newFilesList.push(driveitem);
                });
                if (newFilesList.length > 0) {
                    dispatch({
                        type: appConstants.APP_FILES_TO_UPLOAD,
                        payload: { filesToUpload: newFilesList },
                    });
                    if (Utilities.isNANObject(appState.destinationFolder)) {
                        dispatch(appActions.getServerFiles(''));
                        setFoldersDialogHidden(false);
                    }
                }
                clear();
            }
        }
        loadData();
    }, [plainFiles]);

    const onUpload = async () => {
        Logger.info('onUpload called');
        if (appState.filesToUpload.length == 0) {
            setWarningMessage(
                uiState.translate('main_page_no_attach_selected'),
            );
            setWarningDialogHidden(false);
        } else if (appState.filesToUpload.length > 1) {
            setWarningMessage(
                uiState.translate('main_page_many_attach_selected'),
            );
            setWarningDialogHidden(false);
        } else {
            if (!Utilities.isNANObject(appState.filesToUpload[0].content)) {
                const name = appState.filesToUpload[0].name;
                let newName = name;
                setFileName(name);
                setFileNewName(name);
                let fileExists = true;
                let num = 1;
                do {
                    const result = await webdavApi.exists(
                        appState.appConfig.stubUrl,
                        authentication.user,
                        authentication.userConfig.userInfo.id,
                        appState.destinationFolder.path + '/' + newName,
                    );
                    console.log('exists newName', newName);
                    if (result.success === true && result.result === true) {
                        newName =
                            path.basename(name, path.extname(name)) +
                            '_' +
                            num +
                            path.extname(name);
                        num++;
                    } else fileExists = false;
                } while (fileExists);
                if (name !== newName) {
                    setFileNewName(newName);
                    setFileOverwriteDialogHidden(false);
                    return;
                }
            }

            setProgressDialogHidden(false);
            dispatch(
                appActions.uploadFile(password, date, appState.filesToUpload),
            );
        }
    };

    const onOverwrite = (overwrite: boolean) => {
        Logger.info('onOverwrite called');
        setProgressDialogHidden(false);
        if (overwrite)
            dispatch(
                appActions.uploadFile(password, date, appState.filesToUpload),
            );
        else {
            const filesToUpload = cloneDeep(appState.filesToUpload);
            filesToUpload[0].name = fileNewName;
            dispatch(appActions.uploadFile(password, date, filesToUpload));
        }
    };

    const onSelectLocalFile = (
        _ev?: React.MouseEvent<HTMLButtonElement>,
        _item?: IContextualMenuItem,
    ): void => {
        Logger.info('onSelectLocalFile called');
        openFileSelector();
    };

    const onSelectServerFile = (
        _ev?: React.MouseEvent<HTMLButtonElement>,
        _item?: IContextualMenuItem,
    ): void => {
        dispatch(appActions.getServerFiles(''));
        setFilesDialogHidden(false);
        Logger.info('onSelectServerFile called');
    };

    const showWarning = (message: string): boolean => {
        if (message === '') return true;
        setWarningMessage(message);
        setWarningDialogHidden(false);
        return false;
    };

    const onNext = async () => {
        if (await wizard.pages[wizardPos].validation()) {
            let newPos = wizardPos + 1;
            while (
                validationApi.canSkip(wizard.pages[newPos].id, {
                    password,
                    date,
                })
            ) {
                newPos = newPos + 1;
            }
            setWizardPos(newPos);
        }
    };

    const onPrev = () => {
        setWizardPos(wizardPos - 1);
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'localFiles',
                text: uiState.translate('main_page_local_files'),
                onClick: onSelectLocalFile,
            },
            {
                key: 'serverFiles',
                text: uiState.translate('main_page_server_files'),
                onClick: onSelectServerFile,
            },
        ],
        isBeakVisible: false,
        useTargetWidth: true,
    };

    function getMenu(props: IContextualMenuProps): JSX.Element {
        // Customize contextual menu with menuAs
        return <ContextualMenu {...props} />;
    }

    return (
        <Stack>
            <ScrollablePane
                scrollbarVisibility={ScrollbarVisibility.auto}
                className={appStyles.globalStyles.scrollWizard}
            >
                <Stack className="main-part">
                    {authentication.user !== null &&
                    authentication.userConfig !== null ? (
                        <Stack>
                            {wizard.pages[wizardPos].id === 'files' ? (
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Stack
                                        className={
                                            appStyles.globalStyles
                                                .pageHeaderLayout
                                        }
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Text
                                            className={
                                                appStyles.globalStyles
                                                    .pageHeader
                                            }
                                        >
                                            {uiState.translate(
                                                'sharefiles_header',
                                            )}
                                        </Text>
                                    </Stack>
                                    <Text
                                        className={appStyles.globalStyles.label}
                                        style={{ marginTop: 0 }}
                                    >
                                        {' '}
                                        {uiState.translate('sharefiles_desc')}
                                    </Text>
                                    <Stack horizontal>
                                        <PrimaryButton
                                            styles={appStyles.primaryButton}
                                            style={{ width: '100%' }}
                                            menuProps={menuProps}
                                            menuAs={getMenu}
                                            text={uiState.translate(
                                                'sharefiles_create',
                                            )}
                                        />
                                    </Stack>
                                    <Stack tokens={{ childrenGap: 15 }}>
                                        <WizardFiles
                                            wizardPos={0}
                                            onEdit={setWizardPos}
                                            onDestination={() => {
                                                dispatch(
                                                    appActions.getServerFiles(
                                                        '',
                                                    ),
                                                );
                                                setFoldersDialogHidden(false);
                                            }}
                                        />
                                        <WizardOption
                                            value={password}
                                            notSelectedText={uiState.translate(
                                                'share_nopassword',
                                            )}
                                        />
                                        <WizardOption
                                            value={Utilities.formatLocaleDate(
                                                date,
                                            )}
                                            notSelectedText={uiState.translate(
                                                'share_nodate',
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            ) : wizard.pages[wizardPos].id === 'password' ? (
                                <WizardPassword
                                    value={password}
                                    onChange={setPassword}
                                />
                            ) : wizard.pages[wizardPos].id === 'date' ? (
                                <WizardDate value={date} onChange={setDate} />
                            ) : (
                                <Stack>
                                    <Stack
                                        className={
                                            appStyles.globalStyles
                                                .pageHeaderLayout
                                        }
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: 0,
                                        }}
                                    >
                                        <Text
                                            className={
                                                appStyles.globalStyles
                                                    .pageHeader
                                            }
                                        >
                                            {uiState.translate(
                                                'sharefiles_header',
                                            )}
                                        </Text>
                                    </Stack>
                                    <WizardFiles
                                        wizardPos={0}
                                        onEdit={setWizardPos}
                                        onDestination={() => {
                                            dispatch(
                                                appActions.getServerFiles(''),
                                            );
                                            setFoldersDialogHidden(false);
                                        }}
                                    />
                                    <WizardOptionConfirm
                                        value={password}
                                        notSelectedText={uiState.translate(
                                            'share_nopassword',
                                        )}
                                        wizardPos={1}
                                        onEdit={setWizardPos}
                                        title={uiState.translate(
                                            'sharefolders_password',
                                        )}
                                    />
                                    <WizardOptionConfirm
                                        value={Utilities.formatLocaleDate(date)}
                                        notSelectedText={uiState.translate(
                                            'share_nodate',
                                        )}
                                        wizardPos={2}
                                        onEdit={setWizardPos}
                                        title={uiState.translate(
                                            'sharefolders_date',
                                        )}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    ) : (
                        <></>
                    )}
                    <Stack>
                        <ServerFilesDialog
                            hidden={filesDialogHidden}
                            onDismiss={setFilesDialogHidden}
                            renderFiles={true}
                        />
                        <ServerFilesDialog
                            hidden={foldersDialogHidden}
                            onDismiss={setFoldersDialogHidden}
                            renderFiles={false}
                        />
                        <WarningDialog
                            hidden={warningDialogHidden}
                            subText={warningMessage}
                            icon={'ErrorBadge'}
                            color={'red'}
                            onDismiss={setWarningDialogHidden}
                        />
                        <ProgressDialog
                            hidden={progressDialogHidden}
                            onDismiss={setProgressDialogHidden}
                            title={uiState.translate('progress_header')}
                            subText={uiState.translate('progress_count')}
                        />
                        <FileOverwriteDialog
                            hidden={fileOverwriteDialogHidden}
                            onDismiss={setFileOverwriteDialogHidden}
                            fileName={fileName}
                            fileNewName={fileNewName}
                            onOverwrite={onOverwrite}
                        />
                    </Stack>
                </Stack>
            </ScrollablePane>
            {wizardPos >= 0 ? (
                <Stack className={appStyles.globalStyles.wizardButtons}>
                    {authentication.user !== null &&
                    authentication.userConfig !== null &&
                    wizardPos !== wizard.pages.length - 1 ? (
                        <WizardButtons
                            wizardPos={wizardPos}
                            wizardCount={wizard.pages.length}
                            onNext={onNext}
                            onPrev={onPrev}
                        />
                    ) : authentication.user !== null &&
                      authentication.userConfig !== null &&
                      wizardPos === wizard.pages.length - 1 ? (
                        <Stack
                            className={appStyles.globalStyles.sectionLayout20}
                        >
                            <PrimaryButton
                                styles={appStyles.primaryButton}
                                text={uiState.translate('sharefiles_upload')}
                                onClick={onUpload}
                            />
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
            ) : (
                <></>
            )}
        </Stack>
    );
};

export default withTabs(
    withUserConfigGuard(
        withLicenseLevelGuard(ShareFilePage, LicenseLevel.Standard),
    ),
);
