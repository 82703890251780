import React from 'react';
import { Stack } from '@fluentui/react';
import { PageHeading } from '../../components/common/pageHeading';
import { SettingsPanel } from '../../components/settings/settingsPanel';
import { useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';
import { history } from '../../helpers/history';

export const SettingsRootSection = () => {
    const translate = useAppSelector(selectTranslate);

    return (
        <>
            <PageHeading
                text={translate('settings_header')}
                containerStyle={{ textAlign: 'center' }}
            />
            <Stack tokens={{ childrenGap: 8 }}>
                <SettingsPanel
                    title={translate('settings_account')}
                    onClick={() => history.push('/settings/license')}
                />
                <SettingsPanel
                    title={translate('settings_general')}
                    onClick={() => history.push('/settings/general')}
                />
            </Stack>
        </>
    );
};
