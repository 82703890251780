import { webdavApi } from 'nextcloud-api';
import { z } from 'zod';
import { getRenamedFilePath } from '../helpers/fileExplorer';
import { store } from '../store';
import { selectStubUrl } from '../store/selectors/app';
import { selectUser, selectUserId } from '../store/selectors/auth';
import { RenameFileData } from '../types';
import { selectTranslate } from '../store/selectors/ui';

export const uniqueRenamePathRefinement = async (
    { initialPath, newFilename }: RenameFileData,
    context: z.RefinementCtx,
) => {
    const state = store.getState();
    const user = selectUser(state);
    const stubUrl = selectStubUrl(state);
    const userId = selectUserId(state);
    const translate = selectTranslate(state);
    if (!user || !userId || !stubUrl) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('something_went_wrong'),
        });
        return z.NEVER;
    }

    const newPath = getRenamedFilePath(initialPath, newFilename);

    const res = await webdavApi.exists(stubUrl, user, userId, newPath);

    if (!res.success) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('something_went_wrong'),
        });
        return z.NEVER;
    }

    if (res.result) {
        context.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('file_already_exists'),
        });
        return z.NEVER;
    }

    return null;
};

export const editFilenameFormDataSchema: z.ZodSchema<RenameFileData> = z
    .object({
        initialPath: z.string(),
        newFilename: z.string(),
    })
    .superRefine(uniqueRenamePathRefinement) as z.ZodSchema<RenameFileData>;
