import React from 'react';
import Tab from './tab';
import { history } from '../../helpers';
import { Stack } from '@fluentui/react';
import { appStyles } from '../../helpers';
import { initializeIcons } from '../../icons';
import { selectPageMode, selectTranslate } from '../../store/selectors/ui';
import { useAppSelector } from '../../store';

initializeIcons('/assets/icons/fonts/');

const Tabs = () => {
    const pageMode = useAppSelector(selectPageMode);
    const translate = useAppSelector(selectTranslate);

    const pathParts = history.location.pathname.split('/').filter(Boolean);
    const activeTab = pathParts[0] ?? pageMode;

    const onTabActivated = (tabId) => {
        history.replace('/' + tabId);
    };

    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            className={appStyles.globalStyles.tabs}
        >
            <Stack horizontal>
                {pageMode === 'file' && (
                    <Tab
                        id="file"
                        activeTab={activeTab}
                        label={translate('tabs_sharefiles')}
                        iconName="CloudUpload"
                        onClick={onTabActivated}
                    />
                )}
                {pageMode === 'link' && (
                    <Tab
                        id="link"
                        activeTab={activeTab}
                        label={translate('tabs_sharelink')}
                        iconName="Link"
                        onClick={onTabActivated}
                    />
                )}
                {pageMode === 'explorer' && (
                    <Tab
                        id="explorer"
                        activeTab={activeTab}
                        label={translate('file_explorer')}
                        iconName="FabricFolder"
                        onClick={onTabActivated}
                    />
                )}
            </Stack>
            <Stack horizontal horizontalAlign="center">
                <Tab
                    id="settings"
                    activeTab={activeTab}
                    label={translate('tabs_settings')}
                    iconName="Settings"
                    onClick={onTabActivated}
                />
            </Stack>
        </Stack>
    );
};

export default Tabs;
