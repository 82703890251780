import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Stack, Text, Link } from '@fluentui/react';
import { Utilities, replaceAllInString } from '../../helpers';
import { useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';

const Footer = () => {
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const translate = useAppSelector(selectTranslate);

    const styles = {
        root: {
            paddingLeft: 10,
            paddingRight: 10,
            width: '100%',
        },
    };

    const linkStyles = {
        root: {
            textDecoration: 'none',
        },
    };

    const copyrightText = replaceAllInString(
        translate('footer_copyright_text'),
        {
            '{YEAR}': new Date().getFullYear().toString(),
        },
    );

    return (
        <div className="footer">
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                styles={styles}
            >
                <Text>
                    {Utilities.formatString(
                        translate('footer_version_text_fmt'),
                        appState.appConfig.version,
                    )}
                </Text>
                <Link
                    href={appState.appConfig.organizationPage}
                    target="_blank"
                    styles={linkStyles}
                >
                    {copyrightText}
                </Link>
            </Stack>
        </div>
    );
};

export default Footer;
