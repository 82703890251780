import React from 'react';
import { Stack, Text, FontIcon } from '@fluentui/react';
import { MouseEventHandler } from 'react';
import { appStyles } from '../../helpers';

type SettingsPanelProps = {
    title: string;
    onClick: MouseEventHandler<HTMLElement>;
};

export const SettingsPanel = ({ title, onClick }: SettingsPanelProps) => {
    return (
        <Stack className={appStyles.globalStyles.settingsPanel}>
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                onClick={onClick}
            >
                <Text
                    nowrap
                    block
                    className={appStyles.globalStyles.settingsText}
                >
                    {title}
                </Text>
                <Stack
                    horizontal
                    verticalAlign="center"
                    className={appStyles.globalStyles.settingsIconContainer}
                >
                    <FontIcon
                        iconName="ChevronRightMed"
                        className={appStyles.globalStyles.settingsIcon}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
