import produce from 'immer';
import {
    appConstants,
    NotificationStatus,
} from '../../actions/app/actionTypes';
import { Utilities } from '../../../helpers';
import { ServerFile } from '../../../types';

export type LongOperation = {
    id: string;
    message: string;
};

const initialState = {
    appConfig: {
        appName: '',
        version: '',
        organizationPage: '',
        supportedLanguages: [],
        stubUrl: '',
        nextcloudUrl: '',
        pollInterval: 2,
        cacheTime: 60,
        showLog: false,
        teamApp: {
            MicrosoftAppType: '',
            MicrosoftAppId: '',
            MicrosoftAppPassword: '',
            MicrosoftAppTenantId: '',
            BaseUrl: '',
        },
    },
    longOperation: {
        isFetched: false,
        operations: [] as LongOperation[],
    },
    notificationStatus: {
        status: NotificationStatus.None,
        errorCode: '',
        message: '',
    },
    serverFiles: null as ServerFile[] | null,
    filesToUpload: [],
    destinationFolder: null,
    uploadedSize: 0,
    progressSize: 0,
    transferCompleted: false,
    log: '',
    dialogParams: null,
    searchResult: {
        isSearch: false,
        isSearchComplete: false,
        serverFiles: [],
    },
};

export function appReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.APP_INITIALIZE_ADDIN:
            return produce(state, (draft) => {
                draft.appConfig = action.payload.appConfig;
            });
        case appConstants.APP_START_FETCH:
            return produce(state, (draft) => {
                draft.longOperation.isFetched = true;
                draft.longOperation.operations.push(action.payload);
                draft.notificationStatus = {
                    status: NotificationStatus.None,
                    errorCode: '',
                    message: '',
                };
            });
        case appConstants.APP_STOP_FETCH:
            return produce(state, (draft) => {
                const operationMessageIndex =
                    draft.longOperation.operations.findIndex(
                        (message) => message.id === action.payload.operationId,
                    );

                if (operationMessageIndex !== -1) {
                    draft.longOperation.operations.splice(
                        operationMessageIndex,
                        1,
                    );
                }

                draft.longOperation.isFetched =
                    draft.longOperation.operations.length > 0;

                draft.notificationStatus = {
                    status: Utilities.isNANObject(action.payload.status)
                        ? NotificationStatus.None
                        : action.payload.status,
                    errorCode: Utilities.isNANObject(action.payload.errorCode)
                        ? ''
                        : action.payload.errorCode,
                    message: Utilities.isNANObject(action.payload.message)
                        ? ''
                        : action.payload.message,
                };
            });
        case appConstants.APP_CLEAR_NOTIFICATION:
            return produce(state, (draft) => {
                draft.notificationStatus = {
                    status: NotificationStatus.None,
                    errorCode: '',
                    message: '',
                };
            });
        case appConstants.APP_SHOW_NOTIFICATION:
            return produce(state, (draft) => {
                draft.notificationStatus = {
                    status: action.payload.status,
                    errorCode: action.payload.errorCode,
                    message: action.payload.message,
                };
            });
        case appConstants.APP_SERVER_FILES:
            return produce(state, (draft) => {
                draft.serverFiles = action.payload.serverFiles;
            });
        case appConstants.APP_UPLOADED_SIZE:
            return produce(state, (draft) => {
                draft.uploadedSize =
                    draft.uploadedSize + action.payload.uploadedSize;
            });
        case appConstants.APP_PROGRESS_SIZE:
            return produce(state, (draft) => {
                draft.progressSize = action.payload.progressSize;
            });
        case appConstants.APP_FILES_TO_UPLOAD:
            return produce(state, (draft) => {
                draft.filesToUpload = action.payload.filesToUpload;
            });
        case appConstants.APP_DESTINATION_FOLDER:
            return produce(state, (draft) => {
                draft.destinationFolder = action.payload.destinationFolder;
            });
        case appConstants.APP_TRANSFER_COMPLETED:
            return produce(state, (draft) => {
                draft.transferCompleted = action.payload.transferCompleted;
            });
        case appConstants.APP_LOG:
            return produce(state, (draft) => {
                if (draft.log.length < 32000)
                    draft.log += '\n' + action.payload.log;
            });
        case appConstants.APP_DIALOG_PARAMS:
            return produce(state, (draft) => {
                draft.dialogParams = action.payload.dialogParams;
            });
        case appConstants.APP_SEARCH_RESULT:
            return produce(state, (draft) => {
                draft.searchResult.isSearch = action.payload.isSearch;
                if (!action.payload.isSearch) {
                    draft.searchResult.serverFiles = [];
                    draft.searchResult.isSearchComplete = false;
                }
                if (!Utilities.isNANObject(action.payload.isSearchComplete))
                    draft.searchResult.isSearchComplete =
                        action.payload.isSearchComplete;
                if (!Utilities.isNANObject(action.payload.serverFiles))
                    draft.searchResult.serverFiles = action.payload.serverFiles;
            });
        default:
            return state;
    }
}
