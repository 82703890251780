import { combineReducers } from 'redux';
import { appReducer } from './app/reducer';
import { uiReducer } from './ui/reducer';
import { authentication } from './auth/reducer';
import { msalReducer } from './msal/reducer';

const rootReducer = combineReducers({
    appReducer,
    uiReducer,
    authentication,
    msal: msalReducer,
});

export default rootReducer;
