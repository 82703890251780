import axios, { Axios, AxiosError } from 'axios';
import { store } from '../../store';
import { selectMsalAccessToken } from '../../store/selectors/msal';
import { reauthenticateMsTeamsThunk } from '../../store/actions/msal/thunks';
import { throttle } from '../../helpers';
import { IHttpAuthProvider, MsTeamsUserPresence } from './model';

export class MsGraphApi {
    private axios: Axios;

    constructor(private authProvider: IHttpAuthProvider) {
        this.axios = axios.create({
            baseURL: 'https://graph.microsoft.com/v1.0',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.axios.interceptors.response.use(undefined, async (err) => {
            if (
                err instanceof AxiosError &&
                (err.response.status === 401 || err.response.status === 403)
            ) {
                await this.authProvider.handleAuthError(
                    err.response.status,
                    err.response.data,
                    err.response.headers,
                );
            }

            return Promise.reject(err);
        });
    }

    async getOwnPresence(): Promise<MsTeamsUserPresence | null> {
        const accessToken = await this.authProvider.getToken();
        if (!accessToken) {
            return null;
        }

        try {
            const res = await this.axios.get<MsTeamsUserPresence>(
                '/me/presence',
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                },
            );

            return res.data;
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}

export const msGraphApi = new MsGraphApi({
    getToken() {
        return selectMsalAccessToken(store.getState());
    },
    handleAuthError: throttle(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        store.dispatch(reauthenticateMsTeamsThunk());
    }, 240_000),
});
