import {
    NextcloudUser,
    nextcloudApi,
    Settings,
    NextcloudUserStatusType,
} from 'nextcloud-api';
import { store } from '../../store';
import { selectStubUrl } from '../../store/selectors/app';
import { selectUserSettings, selectUser } from '../../store/selectors/auth';
import { selectMsalAccessTokenExpiresOn } from '../../store/selectors/msal';
import { MsTeamsAvailability, StatusSyncResult } from './model';
import { msGraphApi } from './msGraphApi';
import { msalRefBox } from './msalRefBox';

export const availabilityToUserStatusMap = new Map<
    MsTeamsAvailability,
    NextcloudUserStatusType
>([
    [MsTeamsAvailability.Away, NextcloudUserStatusType.Away],
    [MsTeamsAvailability.DoNotDisturb, NextcloudUserStatusType.DoNotDisurb],
    [MsTeamsAvailability.Available, NextcloudUserStatusType.Online],
    [MsTeamsAvailability.BeRightBack, NextcloudUserStatusType.Away],
    [MsTeamsAvailability.Busy, NextcloudUserStatusType.DoNotDisurb],
    [MsTeamsAvailability.InACall, NextcloudUserStatusType.DoNotDisurb],
    [
        MsTeamsAvailability.InAConferenceCall,
        NextcloudUserStatusType.DoNotDisurb,
    ],
    [MsTeamsAvailability.Inactive, NextcloudUserStatusType.Away],
    [MsTeamsAvailability.InAMeeting, NextcloudUserStatusType.DoNotDisurb],
    [MsTeamsAvailability.Offline, NextcloudUserStatusType.Offline],
    [MsTeamsAvailability.OutOfOffice, NextcloudUserStatusType.Offline],
    [MsTeamsAvailability.PresenceUnknown, NextcloudUserStatusType.Offline],
    [MsTeamsAvailability.Presenting, NextcloudUserStatusType.DoNotDisurb],
    [
        MsTeamsAvailability.UrgentInterruptionsOnly,
        NextcloudUserStatusType.DoNotDisurb,
    ],
]);

export const syncTeamsAndNextcloudStatuses = async (
    stubUrl: string,
    user: NextcloudUser,
): Promise<StatusSyncResult | null> => {
    const presence = await msGraphApi.getOwnPresence();
    if (!presence) {
        return null;
    }

    const status = availabilityToUserStatusMap.get(presence.availability);
    if (!status) {
        return null;
    }

    const nextcloudRes = await nextcloudApi.setOwnStatus(stubUrl, user, status);
    if (!nextcloudRes.success) {
        return null;
    }

    return {
        presence,
        nextcloudUserStatus: nextcloudRes.result.ocs.data,
    };
};

type IJob = {
    run(): void | Promise<void>;
    stop(): void | Promise<void>;
    getStatus(): JobStatus;
};

type JobStatus = 'pending' | 'running' | 'stopped';

class StatusSyncJob implements IJob {
    private timeoutId?: NodeJS.Timeout;
    private settings?: Settings;
    private status: JobStatus = 'pending';

    private onTick = async (): Promise<void> => {
        if (this.status === 'stopped') {
            return;
        }

        const state = store.getState();
        const user = selectUser(state);
        const stubUrl = selectStubUrl(state);
        const userSettings = selectUserSettings(state);

        if (!user || !stubUrl || !userSettings) {
            this.timeoutId = setTimeout(this.onTick, 1_000);
            return;
        }

        this.settings ??= new Settings(userSettings);

        if (!this.settings.StatusSyncEnabled) {
            this.stop();
            return;
        }

        const statusSyncRes = await syncTeamsAndNextcloudStatuses(
            stubUrl,
            user,
        );
        if (!statusSyncRes) {
            this.timeoutId = setTimeout(this.onTick, 65_000);
            return;
        }
        this.timeoutId = setTimeout(this.onTick, 60_000);
    };

    private cleanup() {
        clearTimeout(this.timeoutId);
    }

    public run(): void {
        this.status = 'running';
        this.onTick();
    }

    public stop(): void {
        this.cleanup();
        this.status = 'stopped';
    }

    public getStatus(): JobStatus {
        return this.status;
    }
}

class MsAccessTokenRefreshJob implements IJob {
    private timeoutId: NodeJS.Timeout;
    private INTERVAL_MS = 60_000;
    private REFRESH_PERIOD = this.INTERVAL_MS * 4;
    private status: JobStatus = 'pending';

    private onTick = async (): Promise<void> => {
        if (this.status === 'stopped') {
            return;
        }

        const state = store.getState();
        const tokenExpiresAt = selectMsalAccessTokenExpiresOn(state);
        const msal = msalRefBox.getValue();

        if (!tokenExpiresAt || !msal) {
            this.timeoutId = setTimeout(this.onTick, 1_000);
            return;
        }

        const isCloseToExpiration =
            tokenExpiresAt - Date.now() <= this.REFRESH_PERIOD;

        if (!isCloseToExpiration) {
            this.timeoutId = setTimeout(this.onTick, this.INTERVAL_MS);
            return;
        }

        try {
            const authRes = await msal.acquireTokenSilent({
                account: msal.getActiveAccount() ?? undefined,
                scopes: [],
                forceRefresh: true,
            });
            // since job is async status could have chagned
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (this.status === 'stopped') {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            store.dispatch(setMsalAuthResultThunk(authRes));
        } catch (err) {
            console.error(err);
        }
        this.timeoutId = setTimeout(this.onTick, this.INTERVAL_MS);
    };

    private cleanup() {
        clearTimeout(this.timeoutId);
    }

    public run(): void {
        this.status = 'running';
        this.onTick();
    }

    public stop(): void {
        this.cleanup();
        this.status = 'stopped';
    }

    public getStatus(): JobStatus {
        return this.status;
    }
}

export class StatusSyncJobs {
    private static jobs: IJob[] = [
        new StatusSyncJob(),
        new MsAccessTokenRefreshJob(),
    ];

    public static runAll(): void {
        this.jobs.forEach((job) => job.run());
    }

    public static stopAll(): void {
        this.jobs.forEach((job) => job.stop());
    }
}
