import React, { FC, useEffect } from 'react';
import { useAppSelector } from '../store';
import {
    selectUser,
    selectUserConfig,
    selectUserSettings,
} from '../store/selectors/auth';
import { authActions } from '../store/actions/auth/actions';
import { selectStubUrl } from '../store/selectors/app';
import { useDispatch } from 'react-redux';
import { GuardFallback } from './model';

type Props = {
    fallback?: GuardFallback;
    children: JSX.Element;
};

export const UserConfigGuard = ({
    children,
    fallback,
}: Props): JSX.Element | null => {
    const stubUrl = useAppSelector(selectStubUrl);
    const userConfig = useAppSelector(selectUserConfig);
    const userSettings = useAppSelector(selectUserSettings);
    const user = useAppSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        if (stubUrl === '' || !user) return;

        /* Both are loaded in same function, but set in seperate dispatches
          so if one exists then the other will be set shortly
          removing causes unnesecery rerenders */
        if (userConfig || userSettings) return;

        dispatch(authActions.loadUserConfiguration(user));
    }, [stubUrl, userConfig, userSettings, user]);

    /*User's settings are loaded in the same function as userConfig
      and saved together in the cache */
    const isUserConfigLoaded = !!userConfig && !!userSettings;

    if (!isUserConfigLoaded) {
        if (!fallback) return null;

        const element = typeof fallback === 'function' ? fallback() : fallback;

        return element ?? null;
    }

    return children;
};

export const withUserConfigGuard = <T extends Record<string, unknown>>(
    component: FC<T>,
    fallback?: GuardFallback,
): FC<T> =>
    function WithUserConfigGuard(props) {
        return (
            <UserConfigGuard fallback={fallback}>
                {React.createElement(component, props, props?.children)}
            </UserConfigGuard>
        );
    };
