import React from 'react';
import { Stack } from '@fluentui/react';
// eslint-disable-next-line max-len
import { PageHeadingWithBackButton } from '../../components/common/pageHeadingWithBackButton';
import { history } from '../../helpers/history';
import { LanguageDropdown } from '../../components/settings/languageDropdown';
import { useAppSelector } from '../../store';
import { selectTranslate } from '../../store/selectors/ui';

export const SettingsGeneralSection = () => {
    const translate = useAppSelector(selectTranslate);

    return (
        <>
            <PageHeadingWithBackButton
                text={translate('settings_general')}
                onBackButtonClick={history.goBack}
            />
            <Stack tokens={{ childrenGap: 10 }}>
                <LanguageDropdown />
            </Stack>
        </>
    );
};
