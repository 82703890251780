import {
    IContextualMenuProps,
    ContextualMenu,
    PrimaryButton,
    Dialog,
    DialogType,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { appStyles } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectTranslate } from '../../../store/selectors/ui';
import { useFilePicker } from 'use-file-picker';
import { CreateFolderForm } from '../../form/createFolderForm';
import { uploadFileThunk } from '../../../store/actions/app/thunks';
import { selectServerFiles } from '../../../store/selectors/app';

type Props = {
    pathForUpload: string;
};

export const UploadMenu = ({ pathForUpload }: Props): JSX.Element => {
    const [isFolderDialogHidden, setIsFolderDialogHidden] = useState(true);

    const onShowCreateFolderDialog = (): void => {
        setIsFolderDialogHidden(false);
    };

    const closeCreateFolderDialog = (): void => setIsFolderDialogHidden(true);

    const translate = useAppSelector(selectTranslate);

    const [openFilePicker, { plainFiles }] = useFilePicker({
        readAs: 'ArrayBuffer',
        multiple: false,
    });

    const dispatch = useAppDispatch();
    useEffect(() => {
        const file = plainFiles[0];
        if (!file) {
            return;
        }

        const uploadFile = async (): Promise<void> => {
            dispatch(
                uploadFileThunk({
                    path: pathForUpload,
                    content: await file.arrayBuffer(),
                    fileBase: file.name,
                }),
            );
        };
        uploadFile();
    }, [plainFiles[0]]);

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'uploadFile',
                text: translate('secure_upload_files'),
                onClick: openFilePicker,
            },
            {
                key: 'createFolder',
                text: translate('button_newfolder'),
                onClick: onShowCreateFolderDialog,
            },
        ],
        isBeakVisible: false,
        useTargetWidth: true,
    };

    const getMenu = (props: IContextualMenuProps): JSX.Element => {
        return <ContextualMenu {...props} />;
    };

    return (
        <>
            <PrimaryButton
                styles={appStyles.primaryButton}
                menuProps={menuProps}
                menuAs={getMenu}
                text={translate('add_new')}
            />
            <Dialog
                styles={appStyles.DialogStyle}
                hidden={isFolderDialogHidden}
                onDismiss={closeCreateFolderDialog}
                dialogContentProps={{ type: DialogType.close }}
            >
                <CreateFolderForm
                    pathForCreation={pathForUpload}
                    onCancel={closeCreateFolderDialog}
                />
            </Dialog>
        </>
    );
};

export const CurrentFolderUploadMenu = (): JSX.Element => {
    const serverFiles = useAppSelector(selectServerFiles) ?? [];
    const currentFolderFile = serverFiles[0];
    const uploadPath = currentFolderFile?.parentPath ?? '';

    return <UploadMenu pathForUpload={uploadPath} />;
};
