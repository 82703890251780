import { Settings } from 'nextcloud-api';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import { selectUserSettings } from '../store/selectors/auth';
import { authenticateMsTeamsThunk } from '../store/actions/msal/thunks';
import { selectStubUrl } from '../store/selectors/app';
import { selectMsalActiveAccount } from '../store/selectors/msal';
import { storeKeys } from './storage';

export const useSettings = (): Settings | null => {
    const userSettings = useAppSelector(selectUserSettings);

    const settings = useMemo(() => {
        if (!userSettings) {
            return null;
        }
        return new Settings(userSettings);
    }, [userSettings]);

    return settings;
};

export const useStatusSyncEffect = () => {
    const dispatch = useAppDispatch();
    const stubUrl = useAppSelector(selectStubUrl);
    const settings = useSettings();
    const activeAccount = useAppSelector(selectMsalActiveAccount);

    useEffect(() => {
        const promptLogin =
            sessionStorage.getItem(storeKeys.MSAL_PROMPT_LOGIN) !==
            String(false);
        if (
            !promptLogin ||
            !settings?.StatusSyncEnabled ||
            !stubUrl ||
            activeAccount
        ) {
            return;
        }
        dispatch(authenticateMsTeamsThunk());
    }, [stubUrl, activeAccount, settings?.StatusSyncEnabled]);
};

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(matchMedia(query).matches);

    useLayoutEffect(() => {
        const mediaQuery = matchMedia(query);
        const listener = () => setMatches(mediaQuery.matches);
        mediaQuery.addEventListener('change', listener);
        return () => mediaQuery.removeEventListener('change', listener);
    }, [query]);

    return matches;
};

export const useUnmount = (destructor: () => void): void => {
    useEffect(() => {
        return destructor;
    });
};
