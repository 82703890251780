import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../..';
import { selectStubUrl } from '../../selectors/app';
import { selectUser, selectUserId } from '../../selectors/auth';
import {
    OperationId,
    showNotification,
    startFetch,
    stopFetch,
} from './actionCreators';
import { NotificationStatus, AppErrorCode } from './actionTypes';
import { webdavApi } from 'nextcloud-api';
import { appActions } from './actions';
import * as path from 'path';
import { CreateFolderData, RenameFileData } from '../../../types';
import {
    getRenamedFilePath,
    getStartingPathParts,
} from '../../../helpers/fileExplorer';
import { selectTranslate } from '../../selectors/ui';
import { batch } from 'react-redux';

export const copyFileShareLinkToClipboardThunk = (
    fileId: number,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        const link = `${user.server}/index.php/f/${fileId}`;

        try {
            await navigator.permissions.query({
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                name: 'clipboard-write',
            });
        } catch (err) {
            console.error(err);
            if (!(err instanceof TypeError)) {
                dispatch(
                    showNotification({
                        errorCode: AppErrorCode.CustomError,
                        message: translate('allow_clipboard_access'),
                        status: NotificationStatus.Error,
                    }),
                );
                return;
            }
        }

        try {
            await navigator.clipboard.writeText(link);
        } catch (err) {
            console.error(err);
            dispatch(
                showNotification({
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        dispatch(
            showNotification({
                message: translate('link_copied_to_clipboard'),
                status: NotificationStatus.Success,
            }),
        );
    };
};

export const renameFileThunk = ({
    initialPath,
    newFilename,
}: RenameFileData): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.RenameFile,
                message: translate('renaming_item'),
            }),
        );

        const newPath = getRenamedFilePath(initialPath, newFilename);
        const fileDir = path.parse(newPath).dir;
        const res = await webdavApi.moveFile(
            stubUrl,
            user,
            userId,
            initialPath,
            newPath,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(fileDir));
            dispatch(
                stopFetch({
                    operationId: OperationId.RenameFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(fileDir));

        dispatch(
            stopFetch({
                operationId: OperationId.RenameFile,
            }),
        );
    };
};

export const deleteFileByPathThunk = (
    filePath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.DeleteFile,
                message: translate('deleting_item'),
            }),
        );

        const fileDir = path.parse(filePath).dir;

        const res = await webdavApi.deleteFileByPath(
            stubUrl,
            user,
            userId,
            filePath,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(fileDir));
            dispatch(
                stopFetch({
                    operationId: OperationId.DeleteFile,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(fileDir));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.DeleteFile,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('item_deleted'),
                    status: NotificationStatus.Success,
                }),
            );
        });
    };
};

export const openDownloadLinkThunk = (
    filePath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState): Promise<void> {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        if (!user || !userId) {
            return;
        }

        const url = `${user.server}/remote.php/dav/files/${userId}/${filePath}`;
        window.open(url, '_blank');
    };
};

export const openFileInNextcloudThunk = (
    fileId: number,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return function thunk(dispatch, getState): void {
        const user = selectUser(getState());
        if (!user) {
            return;
        }

        const url = `${user.server}/index.php/f/${fileId}`;
        window.open(url, '_blank');
    };
};

export const getServerFilesWithLoader = (
    parentFolderPath: string,
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, _getState): Promise<void> {
        dispatch(
            startFetch({
                id: OperationId.LoadUserConfiguration,
                message: 'load_serverfiles',
            }),
        );

        await dispatch(appActions.getServerFiles(parentFolderPath));

        dispatch(
            stopFetch({
                operationId: OperationId.LoadUserConfiguration,
            }),
        );
    };
};

export const getInitialServerFilesThunk = (): ThunkAction<
    void,
    RootState,
    unknown,
    AnyAction
> => {
    return function thunk(dispatch, _getState) {
        const startingPathParts = getStartingPathParts();
        if (!startingPathParts) {
            dispatch(getServerFilesWithLoader(''));
            return;
        }
        dispatch(getServerFilesWithLoader(startingPathParts.at(-1).path));
    };
};

type UploadFileData = {
    path: string;
    fileBase: string;
    content: string | ArrayBufferLike;
};

export const uploadFileThunk = ({
    path,
    fileBase,
    content,
}: UploadFileData) => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.CreateFolder,
                message: translate('upload_selected'),
            }),
        );

        const fileExistsRes = await webdavApi.exists(
            stubUrl,
            user,
            userId,
            `${path}/${fileBase}`,
        );
        if (!fileExistsRes.success) {
            await dispatch(appActions.getServerFiles(path));
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }
        if (fileExistsRes.result) {
            await dispatch(appActions.getServerFiles(path));
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('file_already_exists'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        const uploadRes = await webdavApi.copyOrCreateFile(
            stubUrl,
            user,
            userId,
            path,
            `${path}/${fileBase}`,
            content,
            () => {},
        );
        if (!uploadRes.success) {
            await dispatch(appActions.getServerFiles(path));
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('upload_selected_error'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        console.log('uploadRes', uploadRes, path, fileBase);
        await dispatch(appActions.getServerFiles(path));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('upload_finished_header'),
                    status: NotificationStatus.Success,
                }),
            );
        });
    };
};

export const createFolderThunk = ({
    path,
    folderName,
}: CreateFolderData): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async function thunk(dispatch, getState) {
        const state = getState();
        const user = selectUser(state);
        const userId = selectUserId(state);
        const stubUrl = selectStubUrl(state);
        const translate = selectTranslate(state);
        if (!user || !userId || !stubUrl) {
            return;
        }

        dispatch(
            startFetch({
                id: OperationId.CreateFolder,
                message: translate('creating_item'),
            }),
        );

        const res = await webdavApi.createNewFolder(
            stubUrl,
            user,
            userId,
            path,
            folderName,
        );
        if (!res.success) {
            await dispatch(appActions.getServerFiles(path));
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                    errorCode: AppErrorCode.CustomError,
                    message: translate('something_went_wrong'),
                    status: NotificationStatus.Error,
                }),
            );
            return;
        }

        await dispatch(appActions.getServerFiles(path));

        batch(() => {
            dispatch(
                stopFetch({
                    operationId: OperationId.CreateFolder,
                }),
            );
            dispatch(
                showNotification({
                    message: translate('item_created'),
                    status: NotificationStatus.Success,
                }),
            );
        });
    };
};
