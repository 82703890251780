import React, { useCallback } from 'react';
import {
    RouteProps,
    RouteComponentProps,
    Redirect,
    Route,
} from 'react-router-dom';
import { useAppSelector } from '../store';

type PrivateRouteProps = Omit<RouteProps, 'render'>;

export const PrivateRoute = ({
    component: Component,
    ...rest
}: PrivateRouteProps): JSX.Element => {
    const componentWithGuard = useCallback(
        ({ location, ...rest }: RouteComponentProps) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const loggedIn = useAppSelector(
                (state) => state.authentication.loggedIn,
            );

            if (!loggedIn)
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                );

            return <Component location={location} {...rest} />;
        },
        [Component],
    );

    const componentProp = Component ? componentWithGuard : undefined;

    return <Route {...rest} component={componentProp} />;
};
