import React, { FC } from 'react';
import { Tabs } from '../components/tabbed';

export const withTabs = <T extends Record<string, unknown>>(
    component: FC<T>,
): FC<T> =>
    function WithTabs(props: T): JSX.Element {
        return (
            <>
                <Tabs />
                {React.createElement(component, props, props.children)}
            </>
        );
    };
