import { AuthenticationResult } from '@azure/msal-browser';
import produce from 'immer';
import { MsalAction } from '../../actions/msal/actions';
import { MsalActionType } from '../../actions/msal/actionTypes';

type SerializableAuthenticationResult = Omit<
    AuthenticationResult,
    'expiresOn' | 'extExpiresOn'
> & {
    expiresOn: number | null;
    extExpiresOn: number;
};

type IntialState = {
    authResult: SerializableAuthenticationResult | null;
};

const initialState: IntialState = {
    authResult: null,
};

export const msalReducer = (
    state: IntialState = initialState,
    action: MsalAction,
) => {
    switch (action.type) {
        case MsalActionType.SetAuthResult: {
            if (!action.payload) {
                return produce(state, (draft) => {
                    draft.authResult = null;
                });
            }
            return produce(state, (draft) => {
                draft.authResult = {
                    ...action.payload,
                    expiresOn: action.payload.expiresOn?.getTime() ?? null,
                    extExpiresOn: action.payload.extExpiresOn.getTime(),
                };
            });
        }
        default:
            // TODO: change to action once there are more types in action type
            const _exhaustiveCheck: never = action?.type;
    }
    return state;
};
