import React from 'react';
import { SortDirection } from '@tanstack/react-table';
import { ChevroneIcon } from '../../common/chevroneIcon';

type Props = {
    isSorted: false | SortDirection;
};

export const SortingChevrone = ({ isSorted }: Props): JSX.Element | null => {
    if (!isSorted) {
        return null;
    }
    if (isSorted === 'asc') {
        return <ChevroneIcon direction="up" />;
    }
    return <ChevroneIcon direction="down" />;
};
