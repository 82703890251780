import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { LocalStorageHelper, Utilities } from '../../helpers';
import { appApi } from '../../services/appApi';
import { useAppSelector, useAppDispatch } from '../../store';
import { authConstants } from '../../store/actions/auth/actionTypes';
import { uiConstants } from '../../store/actions/ui/actionTypes';
import { selectUserSettings } from '../../store/selectors/auth';
import { selectTranslate } from '../../store/selectors/ui';

export const LanguageDropdown = () => {
    const lang = useAppSelector((state) => state.uiReducer.lang);
    const userSettings = useAppSelector(selectUserSettings);
    const translate = useAppSelector(selectTranslate);
    const dispatch = useAppDispatch();

    if (!userSettings) {
        return null;
    }

    const languageSetting = userSettings.find(
        (setting) => setting.name === 'DefaultLanguage',
    );
    const dropdownOptions = (languageSetting?.values ?? []).map((lang) => ({
        key: String(lang.value),
        text: translate(lang.title),
    }));

    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        _index?: number,
    ): void => {
        console.log('onChangedLang called', option?.key);
        if (!option) {
            return;
        }

        const key = String(option.key);

        LocalStorageHelper.storeSetObject(
            LocalStorageHelper.storeKeys.LANG,
            key,
        );
        dispatch({
            type: uiConstants.APP_CHANGE_LANG,
            payload: { displayLanguage: key },
        });
        dispatch({
            type: authConstants.APP_APPLY_USER_SETTINGS,
            payload: { userSettings: userSettings },
        });

        const userCache = appApi.getCacheUserConfiguration();
        if (Utilities.isNANObject(userCache)) {
            return;
        }

        userCache.userSettings = userSettings;
        appApi.setCacheUserConfiguration(userCache);
    };

    return (
        <Dropdown
            label={translate(languageSetting.resource)}
            selectedKey={lang}
            responsiveMode={2}
            options={dropdownOptions}
            onChange={onChange}
        />
    );
};
