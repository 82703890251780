import React, { CSSProperties } from 'react';
import { IImageProps, Stack, Text, IStackStyles, Image } from '@fluentui/react';
import { useEffect } from 'react';
import { appStyles } from '../../helpers';

export type PathPart = {
    name: string;
    path: string;
};

type BreadcrumbItemProps = {
    name: string;
    path: string;
    onPathPartClick: (e: React.MouseEvent<HTMLElement>, path: string) => void;
};

const pathImage: IImageProps = {
    src: 'assets/breadcrumb.png',
    width: '15px',
};

const BreadcrumbItem = ({
    name,
    path,
    onPathPartClick,
}: BreadcrumbItemProps) => {
    return (
        <Stack horizontal verticalAlign="center" key={name}>
            <Text
                className={appStyles.globalStyles.label}
                style={{ cursor: 'pointer' }}
                onClick={(e): void => onPathPartClick(e, path)}
            >
                {' '}
                {name}
            </Text>
            <Stack verticalAlign="center">
                <Image {...pathImage} />
            </Stack>
        </Stack>
    );
};

type Props = {
    pathParts: PathPart[];
    containerStyle?: CSSProperties;
    onPathPartClick: (e: React.MouseEvent<HTMLElement>, path: string) => void;
};

const breadcrumbsContainerStyles: IStackStyles = {
    root: {
        width: '100%',
    },
};

export const Breadcrumbs = ({
    pathParts,
    onPathPartClick,
    containerStyle,
}: Props) => {
    const BREADCRUMBS_ID = 'breadcrumbs-id';

    useEffect(() => {
        const breadcrumbs = document.getElementById(BREADCRUMBS_ID);
        if (!breadcrumbs) {
            return;
        }
        breadcrumbs.scrollLeft = breadcrumbs.scrollWidth;
    }, [pathParts.length]);

    return (
        <Stack
            id={BREADCRUMBS_ID}
            horizontal={true}
            tokens={{ childrenGap: 2 }}
            style={containerStyle}
            styles={breadcrumbsContainerStyles}
        >
            {pathParts.map((part) => (
                <BreadcrumbItem
                    key={part.name}
                    name={part.name}
                    path={part.path}
                    onPathPartClick={onPathPartClick}
                />
            ))}
        </Stack>
    );
};
