import React from 'react';
import { IFontIconProps, FontIcon } from '@fluentui/react';

type Props = {
    direction: 'up' | 'down';
} & IFontIconProps;

export const ChevroneIcon = ({ direction, ...rest }: Props): JSX.Element => {
    const iconName = direction === 'up' ? 'ChevronUp' : 'ChevronDown';
    return <FontIcon iconName={iconName} {...rest} />;
};
