/**
 * Component displays the dropdown component with predefined (onstants) values
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@fluentui/react';
import produce from 'immer';
import { toString } from 'lodash';
import { appStyles } from '../../helpers';

const DropdownWrapper = (props) => {
    const [state, setState] = useState({ selectedKey: null });

    useEffect(() => {
        setState(
            produce((draft) => {
                draft.selectedKey = toString(props.selectedKey);
            }),
        );
    }, [props]);

    const onChange = (event: React.FormEvent<HTMLDivElement>, item): void => {
        setState(
            produce((draft) => {
                draft.selectedKey = item.key;
            }),
        );
        if (
            props.onSelectionChanged !== null &&
            props.onSelectionChanged !== undefined
        ) {
            props.onSelectionChanged(item.key, props.settingsItem);
        }
    };

    const getSelectedKey = () => {
        //console.log('state.selectedKey ', state.selectedKey);
        return state.selectedKey !== null && state.selectedKey !== undefined
            ? toString(state.selectedKey)
            : toString(props.selectedKey);
    };

    const getStyles = () => {
        const style = {
            dropdown: {
                width:
                    props.dropdownWidth !== null &&
                    props.dropdownWidth !== undefined
                        ? props.dropdownWidth
                        : '100%',
            },
        };
        const globalStyle = appStyles.dropDownStyle.root;
        return {
            ...style,
            globalStyle,
        };
    };

    return (
        <Dropdown
            label={props.title}
            placeholder={props.placeHolder}
            selectedKey={getSelectedKey()}
            responsiveMode={2}
            options={props.valueList}
            onChange={onChange}
            styles={getStyles()}
            disabled={props.disabled}
        />
    );
};

export default DropdownWrapper;

DropdownWrapper.propTypes = {
    title: PropTypes.string,
    placeHolder: PropTypes.string,
    valueList: PropTypes.array,
    selectedKey: PropTypes.any,
    onSelectionChanged: PropTypes.func,
    dropdownWidth: PropTypes.number,
    disabled: PropTypes.bool,
    settingsItem: PropTypes.any,
};
