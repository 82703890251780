import { AuthenticationResult, BrowserAuthError } from '@azure/msal-browser';
import { storeKeys } from '../../helpers';
import { msalRefBox } from './msalRefBox';

export const authenticateMsTeams = async (
    ignorePromptLogin: boolean = false,
): Promise<AuthenticationResult | null> => {
    const msal = msalRefBox.getValue();
    if (!msal) {
        return null;
    }

    const accounts = msal.getAllAccounts();

    try {
        if (accounts.length === 0) {
            const loginHint =
                localStorage.getItem(storeKeys.MSAL_LOGIN_HINT_KEY) ??
                undefined;

            const ssoRes = await msal.ssoSilent({
                loginHint,
            });
            return ssoRes;
        } else if (accounts.length === 1) {
            const silentRes = await msal.acquireTokenSilent({
                account: accounts[0],
                scopes: [],
            });
            return silentRes;
        }
    } catch (err) {
        console.error(err);
    }

    try {
        const promptLogin =
            sessionStorage.getItem(storeKeys.MSAL_PROMPT_LOGIN) !==
            String(false);
        if (!promptLogin && !ignorePromptLogin) {
            return null;
        }
        const authRes = await msal.loginPopup();
        return authRes;
    } catch (err) {
        console.error(err);
        if (
            err instanceof BrowserAuthError &&
            err.errorCode === 'user_cancelled'
        ) {
            sessionStorage.setItem(storeKeys.MSAL_PROMPT_LOGIN, String(false));
        }
        return null;
    }
};
