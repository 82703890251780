import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types';
import { map, cloneDeep, toNumber, forEach } from 'lodash';
import {
    Stack,
    PrimaryButton,
    ActionButton,
    IIconProps,
} from '@fluentui/react';
import { appStyles, Logger } from '../../helpers';
import { appConstants } from '../../store/actions/app/actionTypes';
import { default as WizardOption } from './option';
import { File } from '../common';

const WizardFiles = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const dispatch = useDispatch();

    const [showDestFolder, setShowDestFolder] = useState(false);

    const deleteFile: IIconProps = {
        iconName: 'ChromeClose',
    };
    const _expand: IIconProps = {
        iconName: 'ChevronDownSmall',
    };
    const _collapse: IIconProps = {
        iconName: 'ChevronUpSmall',
    };

    useEffect(() => {
        let showDestFolder = false;
        forEach(appState.filesToUpload, (item) => {
            if (item.content !== null && item.content !== undefined)
                showDestFolder = true;
        });
        setShowDestFolder(showDestFolder);
    }, [appState.filesToUpload]);

    const removeFile = (file, index) => {
        Logger.info('action removeFile');
        const newFilesList = cloneDeep(appState.filesToUpload);
        newFilesList.splice(index, 1);
        dispatch({
            type: appConstants.APP_FILES_TO_UPLOAD,
            payload: { filesToUpload: newFilesList, removed: true },
        });
    };

    const renderFiles = () => {
        return (
            <Stack>
                {appState.filesToUpload.length > 0 ? (
                    map(appState.filesToUpload, (item, index) => {
                        return (
                            <Stack
                                key={index}
                                horizontal
                                verticalAlign="center"
                                horizontalAlign="space-between"
                                style={{ marginTop: 10 }}
                            >
                                <File
                                    item={item}
                                    destinationFolder={
                                        appState.destinationFolder
                                    }
                                ></File>
                                <ActionButton
                                    iconProps={deleteFile}
                                    styles={appStyles.actionButtonRemove}
                                    onClick={() =>
                                        removeFile(item, toNumber(index))
                                    }
                                />
                            </Stack>
                        );
                    })
                ) : (
                    <></>
                )}
            </Stack>
        );
    };

    const onEdit = () => {
        props.onEdit(props.wizardPos);
    };

    return (
        <Stack>
            <Stack className={appStyles.globalStyles.sectionLayoutBorder}>
                <Stack
                    horizontal
                    verticalAlign="center"
                    horizontalAlign="space-between"
                >
                    <WizardOption
                        value={
                            appState.filesToUpload.length === 0
                                ? ''
                                : uiState
                                      .translate('sharefiles_filescount')
                                      .replace(
                                          '{0}',
                                          appState.filesToUpload.length,
                                      )
                        }
                        notSelectedText={uiState
                            .translate('sharefiles_filescount')
                            .replace('{0}', 0)}
                        displayIcon={true}
                    />
                    {props.wizardPos >= 0 ? (
                        <ActionButton
                            styles={appStyles.actionButtonEdit}
                            text={uiState.translate('button_edit')}
                            onClick={onEdit}
                        />
                    ) : (
                        <></>
                    )}
                </Stack>
                {renderFiles()}
                {showDestFolder ? (
                    <Stack horizontalAlign="end" style={{ marginTop: 10 }}>
                        <PrimaryButton
                            styles={appStyles.primaryButtonReverse}
                            text={uiState.translate('button_destinationfolder')}
                            onClick={() => {
                                if (
                                    props.onDestination !== null &&
                                    props.onDestination !== undefined
                                )
                                    props.onDestination();
                            }}
                        />
                    </Stack>
                ) : (
                    <></>
                )}
            </Stack>
        </Stack>
    );
};

export default WizardFiles;

WizardFiles.propTypes = {
    wizardPos: PropTypes.number,
    onEdit: PropTypes.func,
    onDestination: PropTypes.func,
};
