/**
 * Component displays the dropdown component with predefined (onstants) values
 *
 */

import React, { useState, useEffect, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@fluentui/react';
import produce from 'immer';
import { toString } from 'lodash';
import { appStyles } from '../../helpers';

const TextFieldWrapper = (props) => {
    const [state, setState] = useState({ selectedValue: null });

    useEffect(() => {
        setState(
            produce((draft) => {
                draft.selectedValue = toString(props.selectedValue);
            }),
        );
    }, [props]);

    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setState(
            produce((draft) => {
                draft.selectedValue = event.target.value;
            }),
        );
        if (props.onChanged !== null && props.onChanged !== undefined) {
            props.onChanged(event.target.value, props.settingsItem);
        }
    };

    return (
        <TextField
            styles={appStyles.textBoxStyle}
            label={props.title}
            value={state.selectedValue}
            disabled={props.disabled}
            onChange={onChange}
        />
    );
};

export default TextFieldWrapper;

TextFieldWrapper.propTypes = {
    title: PropTypes.string,
    selectedValue: PropTypes.any,
    onChanged: PropTypes.func,
    width: PropTypes.number,
    disabled: PropTypes.bool,
    settingsItem: PropTypes.any,
};
