import {
    LicenseStatusBody,
    LicenseV1,
    LicenseV2,
    LicenseValidator,
    MappedLicenseStatusBody,
    isLicenseNameV2,
    parseLicenseKeyPrefix,
    mapLicenseLevelNameV1ToV2,
    isV2License,
    Assembly,
    LicenseLevelName,
    isLicenseDateExpired,
} from 'nextcloud-api';

// 2 of April local Time
const isNowPastAprilDeadline = (): boolean =>
    Date.now() >= new Date('2024-04-02T00:00:00').getTime();

const isLicenseStatusBodyExpired = (
    licenseStatusBody: UnmappedLicenseStatusBodyCache | LicenseStatusBody,
): boolean => {
    const isV1Prefix =
        parseLicenseKeyPrefix(licenseStatusBody.licensekey) === '';
    const isV1AndPastAprilDeadline = isV1Prefix && isNowPastAprilDeadline();
    const isExpired =
        isV1AndPastAprilDeadline ||
        isLicenseDateExpired(licenseStatusBody.dateExpiration);

    return isExpired;
};

const validateLicense = (licenseKey: string): LicenseV1 | LicenseV2 | null => {
    let validatedLicense: LicenseV1 | LicenseV2 | null = null;
    const prefix = parseLicenseKeyPrefix(licenseKey);

    const validate = () => {
        switch (prefix) {
            case 'v2':
                validatedLicense =
                    LicenseValidator.validateV2LicenseOrFail(licenseKey);
                break;
            case '':
                validatedLicense =
                    LicenseValidator.validateV1LicenseOrFail(licenseKey);
                break;
        }
    };

    try {
        validate();
    } catch (err) {
        console.error(err);
    }

    return validatedLicense;
};

// Cache that could have been saved before the introduction
// of v2 licenses or after that
type UnmappedLicenseStatusBodyCache = Omit<LicenseStatusBody, 'level'> & {
    level: LicenseLevelName;
};

export const getValidatedMappedLicenseStatusBodyCache = (
    licenseStatusBody: UnmappedLicenseStatusBodyCache,
): MappedLicenseStatusBody => {
    const isExpired = isLicenseStatusBodyExpired(licenseStatusBody);

    let licenseLevelName = isLicenseNameV2(licenseStatusBody.level)
        ? licenseStatusBody.level
        : mapLicenseLevelNameV1ToV2(licenseStatusBody.level);

    if (isExpired) {
        licenseLevelName = 'None';
    }

    return {
        ...licenseStatusBody,
        level: licenseLevelName,
    };
};

export const getValidatedMappedLicenseStatusBody = (
    licenseStatusBody: LicenseStatusBody,
    assembly: Assembly,
): MappedLicenseStatusBody => {
    const isExpired = isLicenseStatusBodyExpired(licenseStatusBody);

    if (isExpired)
        return {
            ...licenseStatusBody,
            level: 'None',
        };

    const license = validateLicense(licenseStatusBody.licensekey);

    const isLicenseValid =
        license &&
        license?.subscriptionExpirationDate &&
        !isLicenseDateExpired(license.subscriptionExpirationDate);

    if (!isLicenseValid)
        return {
            ...licenseStatusBody,
            level: 'None',
        };

    if (!isV2License(license))
        return {
            ...licenseStatusBody,
            level: mapLicenseLevelNameV1ToV2(license.subscriptionLevel),
        };

    const assemblyLevel = license.assemblyLevels.find(
        (license) => license.Assembly === assembly,
    );

    const mappedLicenseStatusBody: MappedLicenseStatusBody = {
        ...licenseStatusBody,
        level: assemblyLevel?.Level ?? 'None',
    };

    return mappedLicenseStatusBody;
};
