import React, { useMemo } from 'react';
import {
    Stack,
    Image,
    Text,
    IStackStyles,
    IImageStyles,
    ITextStyles,
} from '@fluentui/react';
import { ShareLinkButton } from './fileShareLinkButton';
import { ServerFile } from '../../../types';
// eslint-disable-next-line max-len
import { FileDropdownButton } from './fileDropdownButton';
import { getImageByContetntType } from '../../../helpers';
import * as path from 'path';

type Props = {
    file: ServerFile;
};

type Styles = {
    container: IStackStyles;
    image: Partial<IImageStyles>;
    name: ITextStyles;
    extension: ITextStyles;
};

const styles: Styles = {
    container: {
        root: {
            minWidth: 0,
        },
    },
    image: {
        root: {
            flexShrink: 0,
        },
    },
    name: {
        root: {
            overflow: 'hidden',
        },
    },
    extension: {
        root: {
            color: 'grey',
            marginLeft: '0 !important',
        },
    },
};

export const FileDetailsContent = ({ file }: Props): JSX.Element => {
    const src = getImageByContetntType(
        file.contenttype,
        file.type,
        file.name,
        file.shared,
    );

    const parsedPath = useMemo(() => path.parse(file.name), [file.name]);

    return (
        <Stack
            horizontal={true}
            tokens={{ childrenGap: '1rem' }}
            horizontalAlign="space-between"
            verticalAlign="center"
        >
            <Stack
                horizontal={true}
                tokens={{ childrenGap: '0.8rem' }}
                horizontalAlign="start"
                verticalAlign="center"
                styles={styles.container}
            >
                <Image src={src} height={32} width={32} styles={styles.image} />
                <Text styles={styles.name}>{parsedPath.name}</Text>
                <Text styles={styles.extension}>{parsedPath.ext}</Text>
            </Stack>
            <Stack
                horizontal={true}
                horizontalAlign="start"
                verticalAlign="center"
            >
                <ShareLinkButton fileId={file.fileId} />
                <FileDropdownButton filePath={file.path} type={file.type} />
            </Stack>
        </Stack>
    );
};
