// eslint-disable-next-line max-len
// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

import { IIconOptions, IIconSubset, registerIcons } from '@uifabric/styling';

export function initializeIcons(
    baseUrl: string = '',
    options?: IIconOptions,
): void {
    const subset: IIconSubset = {
        style: {
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            fontStyle: 'normal',
            fontWeight: 'normal',
            speak: 'none',
        },
        fontFace: {
            fontFamily: '"FabricMDL2Icons"',
            src: `url('${baseUrl}fabric-icons-a9789667.woff') format('woff')`,
        },
        icons: {
            MailSecure: '\uE4D4',
        },
    };

    registerIcons(subset, options);
}
