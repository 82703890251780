/**
 * Contains helper functions to log the application logic
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CryptoJS = require('crypto-js');

const key = '854db2d6-03e3-42f9-a1aa-62d31b463a16';

/**
 * Decrypt value
 * @param val
 */
const decrypt = (val) => {
    const bytes = CryptoJS.AES.decrypt(val, key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return originalText;
};

/**
 * Encrypt value
 * @param val
 */
const encrypt = (val) => {
    return CryptoJS.AES.encrypt(val, key).toString();
};

export const Crypto = {
    decrypt,
    encrypt,
};
