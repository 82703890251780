export class RefBox<T, InitialValue = T> {
    private value: T | InitialValue;

    constructor(initialValue: InitialValue) {
        this.value = initialValue;
    }

    getValue(): T | InitialValue {
        return this.value;
    }

    setValue(value: T) {
        this.value = value;
    }
}
