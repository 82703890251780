import React from 'react';
import { SortDirection } from '@tanstack/react-table';
import { IStackStyles, Stack, Text } from '@fluentui/react';
import { SortingChevrone } from './sortingChevrone';

type HeaderColumnProps = {
    text: string;
    isSorted?: false | SortDirection;
};

type Styles = {
    containerStyles: IStackStyles;
    textStyles: IStackStyles;
};

const styles: Styles = {
    containerStyles: { root: { cursor: 'pointer' } },
    textStyles: { root: { fontWeight: 600 } },
};

export const HeaderColumnContent = ({
    text,
    isSorted = false,
}: HeaderColumnProps): JSX.Element => {
    return (
        <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            horizontalAlign="start"
            verticalAlign="center"
            styles={styles.containerStyles}
        >
            <Text styles={styles.textStyles}>{text}</Text>
            <SortingChevrone isSorted={isSorted} />
        </Stack>
    );
};
