import React, { FC } from 'react';
import { License, LicenseLevel } from 'nextcloud-api';
import { useAppSelector } from '../store';
import { selectIsFetching } from '../store/selectors/app';
import { selectUserConfig } from '../store/selectors/auth';
import { selectUiState } from '../store/selectors/ui';
import { Warning } from '../components/common/warning';

type Props = {
    requiredLicenseLevel: LicenseLevel;
    className?: string;
    children: JSX.Element;
};

export const LicenseLevelGuard = ({
    requiredLicenseLevel,
    className,
    children,
}: Props): JSX.Element => {
    const userConfig = useAppSelector(selectUserConfig);
    const uiState = useAppSelector(selectUiState);
    const isFetching = useAppSelector(selectIsFetching);
    const hasAccess = new License(userConfig).Level >= requiredLicenseLevel;

    if (isFetching) return children;

    if (!hasAccess && userConfig)
        return (
            <Warning
                className={className}
                message={uiState.translate('insufficient_license_level')}
            />
        );

    return children;
};

export const withLicenseLevelGuard = <T extends Record<string, unknown>>(
    component: FC<T>,
    requiredLicenseLevel: LicenseLevel,
): FC<T> =>
    function WithLicenseLevelGuard(props) {
        return (
            <LicenseLevelGuard
                className="license-level-guard-warning-container"
                requiredLicenseLevel={requiredLicenseLevel}
            >
                {React.createElement(component, props, props?.children)}
            </LicenseLevelGuard>
        );
    };
