import React, { useState, useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types';
import { Stack, Text, Label, PrimaryButton } from '@fluentui/react';
import { appStyles, Utilities } from '../../helpers';
import DateTime from '../common/datetime';
import { default as WizardOption } from './option';

const WizardDate = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication,
    );
    const [date, setDate] = useState(props.value);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (props.value === '' && !changed) {
            const dt = new Date();
            dt.setDate(dt.getDate() + 1);
            setDate(dt);
            props.onChange(dt);
        } else {
            setDate(props.value);
        }
    }, [props.value]);

    const clearDate = (e) => {
        e.stopPropagation();
        setChanged(true);
        setDate('');
        props.onChange('');
    };

    const onDateChange = (dt) => {
        setDate(dt);
        props.onChange(dt);
    };

    return (
        <Stack>
            <Stack
                className={appStyles.globalStyles.pageHeaderLayout}
                style={{ textAlign: 'left' }}
            >
                <Text className={appStyles.globalStyles.pageHeader}>
                    {uiState.translate('sharefiles_date_header')}
                </Text>
            </Stack>
            <Stack className={appStyles.globalStyles.wizardContent}>
                <Label className={appStyles.globalStyles.label}>
                    {uiState.translate('sharefiles_date_label')}
                </Label>
                <DateTime date={date} onDateChanged={onDateChange} />
                <Stack
                    horizontal
                    horizontalAlign="end"
                    tokens={{ childrenGap: 10 }}
                >
                    {authentication.filesSharingSettings !== null &&
                    (authentication.filesSharingSettings.ExpireDateEnabled ==
                        false ||
                        (authentication.filesSharingSettings
                            .ExpireDateEnabled == true &&
                            authentication.filesSharingSettings
                                .ExpireDateEnforced === false)) ? (
                        <PrimaryButton
                            styles={appStyles.primaryButtonReverse}
                            text={uiState.translate('clear')}
                            onClick={clearDate}
                        />
                    ) : (
                        <></>
                    )}
                </Stack>
            </Stack>
            <Stack
                horizontal
                className={appStyles.globalStyles.sectionLayoutBorder}
            >
                <WizardOption
                    value={Utilities.formatLocaleDate(date)}
                    notSelectedText={uiState.translate('share_nodate')}
                />
            </Stack>
        </Stack>
    );
};

export default WizardDate;

WizardDate.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
};
