/**
 * Component to display the Expandable pane
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
    Overlay,
    Spinner,
    SpinnerSize,
    Stack,
    getTheme,
    mergeStyleSets,
} from '@fluentui/react';
import { RootState } from '../../store';

const theme = getTheme();

const Waiting = () => {
    const uiState = useSelector((state: RootState) => state.uiReducer);
    const { isFetched, operations } = useSelector(
        (state: RootState) => state.appReducer.longOperation,
    );

    const styles = mergeStyleSets({
        root: {
            background: theme.palette.white,
            padding: 20,
            margin: 20,
        },
        waitingText: {
            paddingTop: 10,
            margin: 0,
            fontSize: 14,
            color: '#201f1e',
            textAlign: 'center',
        },
        waitingPanel: {
            position: 'relative',
            top: '40%',
            transform: 'translateY(-40%)',
        },
    });

    const operation = operations[0];

    if (!isFetched || !operation) {
        return null;
    }

    const getSpinner = () => (
        <Stack tokens={{ childrenGap: 15 }} className={styles.root}>
            <Spinner size={SpinnerSize.large} />
            <div className={styles.waitingText}>
                {uiState.translate(operation.message)}
            </div>
        </Stack>
    );

    const DarkOverlay = () => (
        <Overlay isDarkThemed={true}>
            <div className={styles.waitingPanel}>{getSpinner()}</div>
        </Overlay>
    );

    return (
        <div>
            <DarkOverlay />
        </div>
    );
};

export default Waiting;
