/**
 * Main application actions
 */
import { forEach, find, toString } from 'lodash';
import { store } from '../../../store';
import { Logger } from '../../../helpers/logger';
import { appConstants, NotificationStatus, AppErrorCode } from './actionTypes';
import { Utilities } from '../../../helpers';
import { appApi } from '../../../services/appApi';
import { nextcloudApi, webdavApi } from 'nextcloud-api';
import {
    submitShareFileTask,
    submitShareLinkTask,
} from '../../../services/tasks';

//////////////////////////////////////////////////////////////////////////
//
// Helper functions
//

const _initializeAddin = (payload) => ({
    type: appConstants.APP_INITIALIZE_ADDIN,
    payload,
});

/**
 * Initializes the addin
 * @param inputLocation
 */
const initializeAddin = (_inputLocation: Location) => {
    return async (dispatch) => {
        Logger.info('initializeAddin action called');
        const config = await appApi.getConfig();
        dispatch(_initializeAddin({ appConfig: config }));
    };
};

/**
 * Clears the notifications/errors
 */
const clearNotification = () => {
    return async (dispatch) => {
        Logger.info('The action clearNotification started');
        dispatch({ type: appConstants.APP_CLEAR_NOTIFICATION });
    };
};

/**
 * Get the server files
 */
const getServerFiles = (parentPath: string) => {
    return async (dispatch) => {
        Logger.info('The action getServerFiles');
        const state = store.getState();

        const result = await webdavApi.getServerFiles(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            parentPath,
        );
        console.log('files', result);
        const directoryFilesInfo = await webdavApi.getSharedFiles(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            parentPath,
        );
        console.log('files2', directoryFilesInfo);

        const sharedFiles = [];
        if (directoryFilesInfo.success === true) {
            const parser = new DOMParser();
            const document = parser.parseFromString(
                toString(directoryFilesInfo.result),
                'text/xml',
            );

            const nodes = Utilities.getElementsByTagName(
                document,
                'd:response',
            );
            for (const file of nodes) {
                //filesId.push(Utilities.getElementTextContent(file))
                const href = Utilities.getElementByTagName(file, 'd:href');
                const mount = Utilities.getElementByTagName(
                    file,
                    'nc:mount-type',
                );
                if (href !== null && mount !== null) {
                    const mountGroup = Utilities.getElementTextContent(mount);
                    if (mountGroup === 'group') {
                        let name = decodeURIComponent(
                            Utilities.getElementTextContent(href),
                        );
                        console.log(name, mountGroup);
                        if (name.lastIndexOf('/') === name.length - 1)
                            name = name.substring(0, name.length - 1);
                        if (name.lastIndexOf('/') >= 0)
                            name = name.substring(name.lastIndexOf('/') + 1);
                        sharedFiles.push(name);
                    }
                }
            }
        }
        console.log('sharedFiles', sharedFiles);

        if (result.success === true) {
            const serverFiles = [];
            let shared = false;
            forEach(result.result, (item) => {
                if (item.type === 'directory') {
                    const folder = find(sharedFiles, (file) => {
                        return file === item.basename;
                    });
                    if (!Utilities.isNANObject(folder)) shared = true;
                }
                const driveitem = {
                    fileId: item.props.fileid,
                    name: item.basename,
                    path: `${parentPath}/${item.basename}`,
                    parentPath: parentPath,
                    type: item.type,
                    etag: item.etag,
                    lastmod: item.lastmod,
                    contenttype: item.mime ?? item.props.getcontenttype,
                    contentlength: item.size,
                    selected: false,
                    shared: shared,
                };
                serverFiles.push(driveitem);
            });
            dispatch({
                type: appConstants.APP_SERVER_FILES,
                payload: { serverFiles: serverFiles },
            });
        } else {
            // dispatch({ type:'app.APP_STOP_FETCH', payload: { } });
            dispatch({
                type: appConstants.APP_SHOW_NOTIFICATION,
                payload: {
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                    message: state.uiReducer.translate(
                        'load_serverfiles_error',
                    ),
                },
            });
            return;
        }

        // dispatch({ type:'app.APP_STOP_FETCH', payload: { } });
        //}
    };
};

/**
 * Search the server files
 */
const searchServerFiles = (parentPath: string, mask: string) => {
    return async (dispatch) => {
        Logger.info('The action getServerFiles');
        const state = store.getState();

        dispatch({
            type: appConstants.APP_SEARCH_RESULT,
            payload: { isSearch: true, isSearchComplete: false },
        });

        const result = await webdavApi.searchServerFiles(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            parentPath,
            mask,
        );

        // eslint-disable-next-line max-len
        const location = `/files/${state.authentication.userConfig.userInfo.id}${parentPath}`;
        if (result.success === true) {
            const serverFiles = [];
            forEach(result.result, (item) => {
                let filename = item.filename;
                filename = filename.substring(
                    filename.indexOf(location) + location.length,
                );
                const folder = filename.substring(
                    0,
                    filename.indexOf(item.basename) - 1,
                );
                console.log(filename);
                const driveitem = {
                    name: item.basename,
                    path: filename,
                    parentPath: `${parentPath}${folder}`,
                    type: item.type,
                    etag: item.etag,
                    lastmod: item.lastmod,
                    contenttype: item.mime,
                    contentlength: item.size,
                    selected: false,
                    shared: false,
                };
                if (
                    item.basename !==
                    state.authentication.userConfig.userInfo.id
                )
                    serverFiles.push(driveitem);
            });
            dispatch({
                type: appConstants.APP_SEARCH_RESULT,
                payload: {
                    isSearch: true,
                    isSearchComplete: true,
                    serverFiles: serverFiles,
                },
            });
        } else {
            // dispatch({ type:'app.APP_STOP_FETCH', payload: { } });
            dispatch({
                type: appConstants.APP_SHOW_NOTIFICATION,
                payload: {
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                    message: state.uiReducer.translate(
                        'load_serverfiles_error',
                    ),
                },
            });
            return;
        }
    };
};

/**
 * Create the new folder
 */
const createFolder = (parentPath: string, folder: string) => {
    return async (dispatch) => {
        Logger.info('The action createFolder');
        const state = store.getState();

        console.log('parentPath', parentPath);
        console.log('folder', folder);

        const exists = await webdavApi.checkFolderExist(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            parentPath + '/' + folder,
        );
        if (exists.success === true) {
            if (exists.result == false) {
                await webdavApi.createNewFolder(
                    state.appReducer.appConfig.stubUrl,
                    state.authentication.user,
                    state.authentication.userConfig.userInfo.id,
                    parentPath,
                    folder,
                );
            }
        }

        dispatch(getServerFiles(parentPath));
    };
};

/**
 * Create folders for share files by setting path
 */
const createShareFolders = async (path: string) => {
    Logger.info('The action createShareFolders');
    const state = store.getState();
    const pathToShare = path.split('/');

    let curPath = '';
    let i = 0;
    while (i < pathToShare.length) {
        const item = pathToShare[i];
        const exists = await webdavApi.checkFolderExist(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            curPath + '/' + item,
        );
        if (exists.success === true) {
            if (exists.result == false)
                await webdavApi.createNewFolder(
                    state.appReducer.appConfig.stubUrl,
                    state.authentication.user,
                    state.authentication.userConfig.userInfo.id,
                    curPath,
                    item,
                );
        } else return false;
        curPath = curPath + '/' + item;
        i += 1;
    }
    return true;
};

/**
 * Upload selected files
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const uploadFile = (password: string, dateNotParsed: any, files: any) => {
    return async (dispatch) => {
        Logger.info('The action uploadFile');
        const state = store.getState();

        const date = Utilities.formatUploadDate(dateNotParsed);
        console.log(`parsed to yyyy-MM-dd format date ${date}`);

        const file = files[0];
        let filePath = file.path;
        if (file.content === undefined) {
            dispatch({
                type: appConstants.APP_UPLOADED_SIZE,
                payload: { uploadedSize: file.contentlength },
            });
        } else {
            const successFoldersCreate = await createShareFolders(
                state.appReducer.destinationFolder.path,
            );
            if (successFoldersCreate === false) {
                dispatch({
                    type: appConstants.APP_SHOW_NOTIFICATION,
                    payload: {
                        status: NotificationStatus.Error,
                        errorCode: AppErrorCode.CustomError,
                        message: state.uiReducer.translate(
                            'upload_selected_error',
                        ),
                    },
                });
                return;
            }
            const path = state.appReducer.destinationFolder.path;
            await webdavApi.copyOrCreateFile(
                state.appReducer.appConfig.stubUrl,
                state.authentication.user,
                state.authentication.userConfig.userInfo.id,
                file.path,
                path + '/' + file.name,
                file.content,
                (progress) => {
                    console.log('progressSize: progress ', progress);
                    dispatch({
                        type: appConstants.APP_PROGRESS_SIZE,
                        payload: { progressSize: progress },
                    });
                },
            );
            filePath = path + '/' + file.name;

            dispatch({
                type: appConstants.APP_PROGRESS_SIZE,
                payload: { progressSize: 0 },
            });
            dispatch({
                type: appConstants.APP_UPLOADED_SIZE,
                payload: { uploadedSize: file.content.byteLength },
            });
        }

        let url;
        console.log('filePath', filePath);
        const result = await nextcloudApi.share(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            filePath,
            password,
            date,
        );
        if (result.success === true) {
            console.log('result', result);
            Logger.info(`response.data.url ${result.result.ocs.data.url}`);
            url = result.result.ocs.data.url;
            const nextcloudPath = result.result.ocs.data.path;
            console.log('nextcloudPath', nextcloudPath);
        } else {
            dispatch({
                type: appConstants.APP_SHOW_NOTIFICATION,
                payload: {
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                    message: state.uiReducer.translate('upload_selected_error'),
                },
            });
            return;
        }

        dispatch({
            type: appConstants.APP_TRANSFER_COMPLETED,
            payload: { transferCompleted: true },
        });
        console.log('submit task before');
        submitShareFileTask(url, password, date);
        console.log('submit task after');
    };
};

/**
 * Upload selected files
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shareLink = (files: any) => {
    return async (dispatch) => {
        Logger.info('The action shareLink');
        const state = store.getState();

        const file = files[0];
        const filePath = file.path;
        const fileName = file.name;

        let url;
        console.log('filePath', filePath);

        const result = await webdavApi.getServerFileDetails(
            state.appReducer.appConfig.stubUrl,
            state.authentication.user,
            state.authentication.userConfig.userInfo.id,
            filePath,
        );
        if (result.success === true) {
            const parser = new DOMParser();
            const document = parser.parseFromString(
                toString(result.result),
                'text/xml',
            );
            const node = Utilities.getElementByTagName(document, 'oc:fileid');
            const fileId = Utilities.getElementTextContent(node);
            url = `${state.authentication.user.server}/index.php/f/${fileId}`;
        } else {
            dispatch({
                type: appConstants.APP_SHOW_NOTIFICATION,
                payload: {
                    status: NotificationStatus.Error,
                    errorCode: AppErrorCode.CustomError,
                    message: state.uiReducer.translate(
                        'load_file_properties_error',
                    ),
                },
            });
            return;
        }

        dispatch({
            type: appConstants.APP_TRANSFER_COMPLETED,
            payload: { transferCompleted: true },
        });
        console.log('submit task before', url, fileName);
        submitShareLinkTask(url, fileName);
        console.log('submit task after');
    };
};

export const appActions = {
    initializeAddin,
    clearNotification,
    getServerFiles,
    searchServerFiles,
    uploadFile,
    createFolder,
    shareLink,
};
