import { Header, flexRender } from '@tanstack/react-table';
import React from 'react';
import { ServerFile } from '../../../types';
import { getSortingTitle } from '../../../helpers/fileExplorer';

type Props = Header<ServerFile, unknown>;

export const TableHeader = ({ id, column, getContext }: Props): JSX.Element => {
    const title = column.getCanSort()
        ? getSortingTitle(column.getNextSortingOrder())
        : undefined;

    const onClick = column.getToggleSortingHandler();

    return (
        <th key={id} title={title} onClick={onClick}>
            {flexRender(column.columnDef.header, getContext())}
        </th>
    );
};
