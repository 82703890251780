import * as microsoftTeams from '@microsoft/teams-js';

microsoftTeams.app.initialize();

export const submitShareFileTask = (
    url: string,
    password: string,
    expirationdate: string,
): void => {
    const data = {
        url: url,
        password: password,
        expirationdate: expirationdate,
    };
    microsoftTeams.dialog.url.submit(data);
};

export const submitShareLinkTask = (url: string, name: string): void => {
    const data = {
        url: url,
        name: name,
    };
    microsoftTeams.dialog.url.submit(data);
};
