import { authConstants } from './actionTypes';
import {
    appConstants,
    NotificationStatus,
    AppErrorCode,
} from '../app/actionTypes';
import {
    history,
    UserStorageHelper,
    Logger,
    Utilities,
    LocalStorageHelper,
    getValidatedMappedLicenseStatusBody,
    getValidatedMappedLicenseStatusBodyCache,
} from '../../../helpers';
import { store } from '../../../store';
import {
    nextcloudApi,
    NextcloudUser,
    License,
    Theme,
    Settings,
    LicenseLevel,
    LicensePermissions,
    Assembly,
} from 'nextcloud-api';
import { appApi } from '../../../services/appApi';
import { OperationId, startFetch, stopFetch } from '../app/actionCreators';

function signin(serverUrl: string) {
    return async (dispatch) => {
        const state = store.getState();
        dispatch(
            startFetch({
                id: OperationId.SignIn,
                message: state.uiReducer.translate('login_to_account_fetch'),
            }),
        );

        LocalStorageHelper.storeSetObject(
            LocalStorageHelper.storeKeys.SERVER_URL,
            serverUrl,
        );

        // To initiate a login do an anonymous POST request
        const result = await nextcloudApi.loginInit(
            state.appReducer.appConfig.stubUrl,
            serverUrl,
        );
        if (result.success === true) {
            console.log('The action signin');
            const resultInit = result.result;

            // The url in login should be opened in the default browser,
            // this is where the user will follow the login procedure.
            window.open(resultInit.login, '_blank');

            // The program should directly start polling the poll endpoint
            runLoginChecker(dispatch, resultInit);

            dispatch(
                stopFetch({
                    operationId: OperationId.SignIn,
                }),
            );
        } else {
            dispatch({ type: 'users.LOGIN_FAILED', payload: {} });
            dispatch(
                stopFetch({
                    operationId: OperationId.SignIn,
                }),
            );
        }
    };
}

function logout(_isUI: boolean) {
    return async (dispatch) => {
        Logger.info('The action logout');
        const state = store.getState();

        dispatch(
            startFetch({
                id: OperationId.Logout,
                message: state.uiReducer.translate('logout_fetch'),
            }),
        );
        dispatch({ type: 'users.LOGOUT', payload: {} });
        UserStorageHelper.storeClearUser();
        appApi.clearCacheUserConfiguration();
        history.replace('/login');
        dispatch(
            stopFetch({
                operationId: OperationId.Logout,
            }),
        );
    };
}

function loadUserConfiguration(user: NextcloudUser) {
    return async (dispatch) => {
        const state = store.getState();
        console.log('The action loadUserConfiguration');

        const userCache = appApi.getCacheUserConfiguration();
        if (!Utilities.isNANObject(userCache)) {
            userCache.userConfig.licenseStatus =
                getValidatedMappedLicenseStatusBodyCache(
                    userCache.userConfig.licenseStatus,
                );
            dispatch({
                type: authConstants.APP_CHANGE_USER_CONFIG,
                payload: { userConfig: userCache.userConfig },
            });
            dispatch({
                type: authConstants.APP_CHANGE_USER_SETTINGS,
                payload: {
                    userSettings: userCache.userSettings,
                    versionSettings: userCache.versionSettings,
                    filesSharingSettings: userCache.filesSharingSettings,
                    passwordPolicySettings: userCache.passwordPolicySettings,
                    theme: new Theme(userCache.theme),
                },
            });
        } else {
            dispatch(
                startFetch({
                    id: OperationId.LoadUserConfiguration,
                    message: state.uiReducer.translate('load_configuration'),
                }),
            );

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const userConfig: any = {};

            const userResult = await nextcloudApi.getUser(
                state.appReducer.appConfig.stubUrl,
                user,
            );
            console.log('userConfig userResult: ' + userResult);
            if (userResult.success === true) {
                userConfig['userInfo'] = userResult.result.ocs.data;
            }

            if (!userResult.success) {
                Logger.info('The action logout');
                dispatch({ type: 'users.LOGOUT', payload: {} });
                UserStorageHelper.storeClearUser();
                appApi.clearCacheUserConfiguration();
                dispatch(
                    stopFetch({
                        operationId: OperationId.LoadUserConfiguration,
                    }),
                );
                return;
            }

            let result = await nextcloudApi.getPing(
                state.appReducer.appConfig.stubUrl,
                user,
            );
            if (result.success === true) {
                userConfig['ping'] = result.result;
            }
            result = await nextcloudApi.getStatus(
                state.appReducer.appConfig.stubUrl,
                user,
            );
            if (result.success === true) {
                userConfig['status'] = result.result;
            } else {
            }
            const licenseResult = await nextcloudApi.getLicenceStatus(
                state.appReducer.appConfig.stubUrl,
                user,
            );

            console.log(
                // eslint-disable-next-line max-len
                `getLicenseStatus licenseResult.success= ${licenseResult.success}`,
            );
            if (licenseResult.success) {
                userConfig.licenseStatus = getValidatedMappedLicenseStatusBody(
                    licenseResult.result,
                    Assembly.MsTeamsAddin,
                );
            }

            const settingsConfig = await appApi.getSettingsConfig();
            let userSettings = settingsConfig.settings;
            let filesSharingSettings = settingsConfig.filesSharing;
            let passwordPolicySettings = settingsConfig.passwordPolicy;
            let versionSettings = settingsConfig.version;
            let themeSettings = settingsConfig.theme;
            let theme = null;

            let userServerSettings = [];

            const license = new License(userConfig);
            const {
                generalScope: { canUseServerApp },
            } = new LicensePermissions(license.Level);
            if (canUseServerApp) {
                result = await nextcloudApi.getSettingKeys(
                    state.appReducer.appConfig.stubUrl,
                    user,
                );
                if (result.success === true) {
                } else {
                    dispatch(
                        stopFetch({
                            operationId: OperationId.LoadUserConfiguration,
                        }),
                    );
                    dispatch({
                        type: appConstants.APP_SHOW_NOTIFICATION,
                        payload: {
                            status: NotificationStatus.Error,
                            errorCode: AppErrorCode.CustomError,
                            message: state.uiReducer.translate(
                                'load_user_configuration_error',
                            ),
                        },
                    });
                    return;
                }
                result = await nextcloudApi.getSettingValues(
                    state.appReducer.appConfig.stubUrl,
                    user,
                );
                if (result.success === true) {
                    userServerSettings = result.result;
                } else {
                    dispatch(
                        stopFetch({
                            operationId: OperationId.LoadUserConfiguration,
                        }),
                    );
                    dispatch({
                        type: appConstants.APP_SHOW_NOTIFICATION,
                        payload: {
                            status: NotificationStatus.Error,
                            errorCode: AppErrorCode.CustomError,
                            message: state.uiReducer.translate(
                                'load_user_configuration_error',
                            ),
                        },
                    });
                    return;
                }
            }
            result = await nextcloudApi.getCapabilities(
                state.appReducer.appConfig.stubUrl,
                user,
            );
            let userCapabilities = null;
            if (result.success === true) {
                userCapabilities = result.result;
            } else {
            }
            console.log('userCapabilities ', userCapabilities);

            // Apply server settings
            let settings = new Settings(userSettings);
            userSettings = settings.applyServerSettings(
                userSettings,
                userServerSettings,
            );

            // Apply license settings
            settings = new Settings(userSettings);
            if (!settings.DisableSettings) {
                settings.getSettingsByName('General').display = true;
                settings.getSettingsByName('SendMode').display = true;
                settings.getSettingsByName('DisableSettings').display = true;
                settings.getSettingsByName('AttachmentSettings').display = true;
                settings.getSettingsByName('InterceptMode').display = true;
                settings.getSettingsByName('Threshold').display = true;
                settings.getSettingsByName(
                    'ShareFilesAndPublicFolder',
                ).display = true;
                settings.getSettingsByName('HtmlSnippetUploadFiles').display =
                    true;
                settings.getSettingsByName('HtmlSnippetPublicShare').display =
                    true;
                settings.getSettingsByName('SecureMail').display = true;
                settings.getSettingsByName('SecureMailEnabled').display = true;
                settings.getSettingsByName('SecureMailEnforced').display = true;
                settings.getSettingsByName('HtmlSnippetSecureMail').display =
                    true;
                settings.getSettingsByName('GuestAccounts').display = true;
                settings.getSettingsByName('GuestAccountsEnabled').display =
                    true;
                settings.getSettingsByName('GuestAccountsEnforced').display =
                    true;
                settings.getSettingsByName('Talk').display = true;
                settings.getSettingsByName('TalkEnabled').display = true;
                settings.getSettingsByName('GenerateTalkPassword').display =
                    true;

                if (license.Level >= LicenseLevel.Standard) {
                    settings.getSettingsByName('SendMode').enabled = true;
                    settings.getSettingsByName('InterceptMode').enabled = true;
                    settings.getSettingsByName('Threshold').enabled = true;
                }
                if (license.Level >= LicenseLevel.Plus) {
                    settings.getSettingsByName(
                        'HtmlSnippetUploadFiles',
                    ).enabled = true;
                    settings.getSettingsByName(
                        'HtmlSnippetPublicShare',
                    ).enabled = true;
                    settings.getSettingsByName('SecureMailEnabled').enabled =
                        true;
                    settings.getSettingsByName('SecureMailEnforced').enabled =
                        true;
                    settings.getSettingsByName(
                        'HtmlSnippetSecureMail',
                    ).enabled = true;
                    settings.getSettingsByName('GuestAccountsEnabled').enabled =
                        true;
                    settings.getSettingsByName(
                        'GuestAccountsEnforced',
                    ).enabled = true;
                    themeSettings = settings.applyTheme(
                        settingsConfig.theme,
                        userServerSettings,
                    );
                    theme = new Theme(themeSettings);
                }
                settings.getSettingsByName('TalkEnabled').enabled = true;
                settings.getSettingsByName('GenerateTalkPassword').enabled =
                    true;
            }

            versionSettings = settings.applyVersionSettings(
                versionSettings,
                userCapabilities.ocs,
            );
            console.log('versionSettings ', versionSettings);
            filesSharingSettings = settings.applyFileSharingSettings(
                filesSharingSettings,
                userCapabilities.ocs,
            );
            console.log('filesSharingSettings ', filesSharingSettings);
            passwordPolicySettings = settings.applyPasswordPolicySettings(
                passwordPolicySettings,
                userCapabilities.ocs,
            );
            console.log('passwordPolicySettings ', passwordPolicySettings);

            dispatch({
                type: authConstants.APP_CHANGE_USER_CONFIG,
                payload: { userConfig: userConfig },
            });
            console.log('userSettings', userSettings);
            dispatch({
                type: authConstants.APP_CHANGE_USER_SETTINGS,
                payload: {
                    userSettings: userSettings,
                    versionSettings,
                    filesSharingSettings,
                    passwordPolicySettings,
                    theme,
                },
            });

            if (!userResult.success) {
                dispatch(
                    stopFetch({
                        operationId: OperationId.LoadUserConfiguration,
                    }),
                );
                dispatch({
                    type: appConstants.APP_SHOW_NOTIFICATION,
                    payload: {
                        status: NotificationStatus.Error,
                        errorCode: AppErrorCode.CustomError,
                        message: state.uiReducer.translate(
                            'load_user_configuration_error',
                        ),
                    },
                });
                return;
            }

            const userCache = {
                dateAdded: new Date().toISOString(),
                userConfig: userConfig,
                userSettings: userSettings,
                versionSettings: versionSettings,
                filesSharingSettings: filesSharingSettings,
                passwordPolicySettings: passwordPolicySettings,
                theme: themeSettings,
            };
            appApi.setCacheUserConfiguration(userCache);

            dispatch(
                stopFetch({
                    operationId: OperationId.LoadUserConfiguration,
                }),
            );
        }
    };
}

const loginCheckerThread = async (dispatch, resultInit) => {
    const state = store.getState();
    try {
        console.log('loginCheckerThread start');
        const result = await nextcloudApi.loginCheck(
            state.appReducer.appConfig.stubUrl,
            resultInit.poll,
        );
        if (result.success === true) {
            dispatch({
                type: authConstants.LOGIN_PROCESS,
                payload: { user: result.result },
            });
            dispatch({ type: 'users.LOGIN_SUCCESS', payload: {} });
            UserStorageHelper.storeSetUser(result.result, true);
            // dispatch({ type:'app.APP_STOP_FETCH', payload: { } });

            return;
        }
    } catch (ex) {}

    // Continue thread
    setTimeout(function run() {
        loginCheckerThread(dispatch, resultInit);
    }, state.appReducer.appConfig.pollInterval * 1000);
};

/**
 * Runs the function to check logins
 */
const runLoginChecker = (dispatch, resultInit) => {
    console.log('runSessionChecker called');
    const state = store.getState();
    setTimeout(function run() {
        loginCheckerThread(dispatch, resultInit);
    }, state.appReducer.appConfig.pollInterval * 1000);
};

export const authActions = {
    logout,
    signin,
    loadUserConfiguration,
};
