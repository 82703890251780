import { AppErrorCode as LibraryAppErrorCode } from 'nextcloud-api';
import { ReduxAction, createReduxAction } from '../../utils';
import { AppErrorCode, NotificationStatus, appConstants } from './actionTypes';
import { LongOperation } from '../../reducers/app/reducer';

export enum OperationId {
    Logout = 'logout',
    SignIn = 'signin',
    AuthenticateMsTeams = 'authenticate_ms_teams',
    LoadUserConfiguration = 'load_user_configuration',
    RenameFile = 'rename_file',
    DeleteFile = 'delete_file',
    CreateFolder = 'create_folder',
}

export type StartFetchActionPayload = LongOperation;

export type StartFetchAction = ReduxAction<
    typeof appConstants.APP_START_FETCH,
    StartFetchActionPayload
>;

export const startFetch = (
    payload: StartFetchActionPayload,
): StartFetchAction => createReduxAction(appConstants.APP_START_FETCH, payload);

export type StopFetchActionPayload = {
    operationId: OperationId;
    status?: NotificationStatus;
    errorCode?: AppErrorCode | LibraryAppErrorCode;
    message?: string;
};

export type StopFetchAction = ReduxAction<
    typeof appConstants.APP_STOP_FETCH,
    StopFetchActionPayload
>;

export const stopFetch = (payload: StopFetchActionPayload): StopFetchAction =>
    createReduxAction(appConstants.APP_STOP_FETCH, payload);

type ShowNotificationPayload = {
    status: NotificationStatus;
    errorCode?: AppErrorCode | LibraryAppErrorCode;
    message: string;
};

export type ShowNotificationAction = ReduxAction<
    typeof appConstants.APP_SHOW_NOTIFICATION,
    ShowNotificationPayload
>;

export const showNotification = (
    payload: ShowNotificationPayload,
): ShowNotificationAction =>
    createReduxAction(appConstants.APP_SHOW_NOTIFICATION, payload);
