import { Stack, Text } from '@fluentui/react';
import { appStyles } from '../../helpers';
import React from 'react';

type Props = {
    message: string;
    className?: string;
};

export const Warning = ({ message, className }: Props): JSX.Element => (
    <Stack
        // eslint-disable-next-line max-len
        className={`${appStyles.globalStyles.errorLoginStack} ${className ?? ''}`}
        horizontalAlign="stretch"
    >
        <Text className={appStyles.globalStyles.text}>{message}</Text>
    </Stack>
);
