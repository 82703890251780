import React from 'react';
import PropTypes from 'prop-types';
import { Stack, FontIcon, Label } from '@fluentui/react';
import { appStyles } from '../../helpers';

const Tab = (props) => {
    const onTabActivated = () => {
        console.log('onTabActivated clicked');
        props.onClick(props.id);
    };

    const getClassName = (): string => {
        return props.activeTab === props.id
            ? appStyles.globalStyles.tabsItemActive
            : appStyles.globalStyles.tabsItem;
    };

    return (
        <Stack className={getClassName()}>
            <Stack horizontalAlign="center" onClick={onTabActivated}>
                <FontIcon iconName={props.iconName} style={{ fontSize: 20 }} />
                <Label className={appStyles.globalStyles.tabsLabel}>
                    {props.label}
                </Label>
            </Stack>
        </Stack>
    );
};

export default Tab;

Tab.propTypes = {
    id: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
