import { NextcloudUserStatus } from 'nextcloud-api';

export enum MsTeamsAvailability {
    Away = 'Away',
    DoNotDisturb = 'DoNotDisturb',
    Available = 'Available',
    BeRightBack = 'BeRightBack',
    Busy = 'Busy',
    InACall = 'InACall',
    InAConferenceCall = 'InAConferenceCall',
    Inactive = 'Inactive',
    InAMeeting = 'InAMeeting',
    Offline = 'Offline',
    OffWork = 'OffWork',
    OutOfOffice = 'OutOfOffice',
    PresenceUnknown = 'PresenceUnknown',
    Presenting = 'Presenting',
    UrgentInterruptionsOnly = 'UrgentInterruptionsOnly',
}

export type IHttpAuthProvider<T = unknown> = {
    getToken: () => string | Promise<string | null> | null;
    handleAuthError: (
        statusCode: number,
        data: T,
        headers: Record<string, string>,
    ) => unknown | Promise<unknown>;
};

export type MsTeamsUserPresence = {
    id: string;
    availability: MsTeamsAvailability;
    acitivity: string;
};

export type StatusSyncResult = {
    presence: MsTeamsUserPresence;
    nextcloudUserStatus: NextcloudUserStatus;
};
