import React, { useState, useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Stack, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import { appStyles, Utilities } from '../../helpers';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { SettingsRootSection } from './settingsRootSection';
import { SettingsGeneralSection } from './settingsGeneralSection';
import { SettingsAccountSection } from './settingsAccountSection';
import { withUserConfigGuard } from '../../hoc/userConfigGuard';
import { withTabs } from '../../hoc/tabs';

const SettingsLayout = () => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const authentication = useSelector(
        (state: RootStateOrAny) => state.authentication,
    );
    const [_lang, setLang] = useState('en');

    useEffect(() => {
        async function loadData() {
            if (!Utilities.isNANObject(authentication.userSettings)) {
                if (uiState.lang !== '') {
                    setLang(uiState.lang);
                }
            }
        }
        loadData();
    }, [authentication.userSettings, uiState.lang]);

    const { path } = useRouteMatch();

    return (
        <Stack>
            <ScrollablePane
                scrollbarVisibility={ScrollbarVisibility.auto}
                className={appStyles.globalStyles.scrollWizardNoConfirm}
            >
                <Stack className="main-part">
                    {authentication.user !== null &&
                        authentication.userConfig !== null &&
                        authentication.userSettings !== null && (
                            <Stack>
                                <Switch>
                                    <Route
                                        path={`${path}/license`}
                                        component={SettingsAccountSection}
                                    />
                                    <Route
                                        path={`${path}/general`}
                                        component={SettingsGeneralSection}
                                    />
                                    <Route
                                        path={`${path}`}
                                        component={SettingsRootSection}
                                    />
                                </Switch>
                            </Stack>
                        )}
                </Stack>
            </ScrollablePane>
        </Stack>
    );
};

export default withTabs(withUserConfigGuard(SettingsLayout));
