import { ITextFieldProps, TextField } from '@fluentui/react';
import React from 'react';
import { ControllerProps, Controller } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ControlledTextField = <T extends Record<string, any>>(
    props: Omit<ControllerProps<T>, 'render'> & ITextFieldProps,
) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => (
                <TextField
                    {...props}
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    errorMessage={error && error.message}
                    defaultValue={undefined}
                />
            )}
        />
    );
};
