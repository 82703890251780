import { AccountInfo } from '@azure/msal-browser';
import { RootState } from '..';

export const selectMsalAccessTokenExpiresOn = (
    state: RootState,
): number | null => state.msal.authResult?.expiresOn ?? null;

export const selectMsalAccessToken = (state: RootState): string | null =>
    state.msal.authResult?.accessToken ?? null;

export const selectMsalActiveAccount = (state: RootState): AccountInfo | null =>
    state.msal.authResult?.account ?? null;
